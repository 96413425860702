import React, { useRef, useState } from 'react';
import toast from 'react-hot-toast';
import {createProduct} from '../../../services/operations/productOperations';
import { useDispatch, useSelector } from 'react-redux';
import Resizer from 'react-image-file-resizer';
import validatebarcode from 'barcode-validator/src/validatebarcode';
import { isMobile } from 'react-device-detect';
import { IoIosCloseCircle, IoMdBarcode } from "react-icons/io";
import { BarcodeScanner } from 'react-barcode-scanner';
import 'react-barcode-scanner/polyfill';

function AddProduct() {
    const dispatch = useDispatch();
    const ref = useRef(null);
    const {productLoader} = useSelector((state)=>(state.product));
    const {token} = useSelector(state => state.auth);
    const [barScanner,setBarScanner] = useState(false);
    const [imgPreview,setImgPreview] = useState(null);
    const [imgFile,setImgFile] = useState(null);
    const initialData = {listingStatus:'active',category:'',title:'',description:'',costPrice:'',sellingPrice:'',mrp:'',thumbnailImage:'',flag:'none',itemCode:'NOCODE',quantity:''}
    const [formData , setFormData] = useState(initialData);
    const {productCategories} = useSelector((state) => (state.product));
    const resizeFile = (file) => {
        
            Resizer.imageFileResizer(
                file,
                400, // new image max width
                400, // new image max height
                'WEBP', // default type
                95, // new image quality
                0, // rotation degree
                (uri) => {
                      const reader = new FileReader();
                      reader.readAsDataURL(uri);
                      reader.onloadend = () => {
                      setImgPreview(reader.result);
                      setImgFile(uri);
                  }
                },
                'file' // output type
              );
    }
    const changeHandler = (event) => {
        if (event.target.name === 'costPrice' || event.target.name === 'sellingPrice' || event.target.name === 'mrp' || event.target.name === 'quantity')
        {
            const result = event.target.value.replace(/[^0-9.]/g, '')
            event.target.value=result

        }
        setFormData((prev) => (
            {
                ...prev,[event.target.name]:event.target.value
            }
        ));
    }
    const handleImgSelect = (e) => {
        const file = e.target.files[0];
        if(file){
            resizeFile(file);
        }
        else{
            setImgPreview(null);
            setImgFile(null);
        }
    }
    const submitHandler = (event) => {
        var regexp = /^\d+(\.\d{1,2})?$/;
        var regexp2 = /^\d+(\.\d{1,3})?$/;
        event.preventDefault();
        if(!formData.category){
            return toast.error('Please Select Product category');
        }
        if(!formData.title.trim()){
            return toast.error('Product Name is Required');
        }
        if(!formData.description.trim()){
            return toast.error('About Product is Required');
        }
        if(!formData.itemCode){
            return toast.error('Product Code is Required');
        }
        if(formData.itemCode !== 'NOCODE' && !validatebarcode(formData.itemCode)){
            return toast.error('Enter a Valid Product Code or NOCODE');
        }
        if(!formData.quantity){
            return toast.error('Quantity is Required');
        }
        if(!regexp2.test(formData.quantity)){
            return toast.error('Enter Valid Quantity upto 3 decimals');
        }
        if(!formData.costPrice){
            return toast.error('Cost Price is Required');
        }
        if(!regexp.test(formData.costPrice)){
            return toast.error('Enter Valid Cost Price upto 2 decimals');
        }
        if(!formData.sellingPrice){
            return toast.error('Selling Price is Required');
        }
        if(!regexp.test(formData.sellingPrice)){
            return toast.error('Enter Valid Selling Price upto 2 decimals');
        }
        if(!formData.mrp){
            return toast.error('MRP is Required');
        }
        if(!regexp.test(formData.mrp)){
            return toast.error('Enter Valid MRP upto 2 decimals');
        }
        if(!imgPreview){
            return toast.error('Please Select Product Image');
        }
        try
        {
            const allData = new FormData();
            allData.append("thumbnailImage" , imgFile);
            allData.append("listingStatus",formData.listingStatus);
            allData.append("category",formData.category);
            allData.append("title",formData.title);
            allData.append("description",formData.description);
            allData.append("costPrice",formData.costPrice);
            allData.append("sellingPrice",formData.sellingPrice);
            allData.append("mrp",formData.mrp);
            allData.append("flag",formData.flag);
            allData.append("itemCode",formData.itemCode);
            allData.append("quantity",formData.quantity);
            dispatch(createProduct(token,allData,setFormData,initialData,setImgPreview,setImgFile));
        }
        catch(error)
        {
            console.log(error)
        }
    }

    return (
        <div className='page-container'>
            <div className='dark-block'>
                <div className='dash-forms'>
                    <form className='dash-form-container' onSubmit={submitHandler} autoComplete='off'>
                        <div className='main-title'>
                            Add New Product
                        </div>
                        <div className='main-des'>
                            Enter product details to add a new product to your catalog.
                            <span className='highlight-des'></span>
                        </div>
                        <div className='radio-btn'>
                            <label>
                                <input
                                type='radio'
                                name='listingStatus'
                                value='active'
                                onChange={changeHandler}
                                defaultChecked/>
                                <span>Active</span>
                            </label>
                            <label>
                                <input
                                type='radio'
                                name='listingStatus'
                                value='inactive'
                                onChange={changeHandler}
                                />
                                <span>Inactive</span>
                            </label>
                        </div>
                        <div className='main-form'>
                            <div className='form-item'>
                                <label htmlFor='category'>Product Category <sup>*</sup></label>
                                <select  
                                    name='category'
                                    value={formData.category}
                                    onChange={changeHandler}
                                >
                                <option value=''>--Select Product Category--</option>
                                {
                                    productCategories.length > 0 &&
                                        productCategories.map((category) => (
                                            <option value={category.title} key={category._id} >
                                                {category.title}
                                            </option>
                                        ))
                                }
                                </select>
                            </div>
                            <div className='form-item'>
                                <label htmlFor='title'>Product Name <sup>*</sup></label>
                                <input 
                                    type='text' 
                                    name='title' 
                                    placeholder='Enter product name'
                                    value={formData.title}
                                    onChange={changeHandler}
                                />
                            </div>
                            <div className='form-item'>
                                <label htmlFor='description'>About Product<sup>*</sup></label>
                                <input 
                                    type='text' 
                                    name='description' 
                                    placeholder='Enter about product'
                                    value={formData.description}
                                    onChange={changeHandler}
                                />
                            </div>
                            <div className='form-items-container'>
                                <div className='form-item'>
                                    <label htmlFor='itemCode'>Product Code (UPC)<sup>*</sup></label>
                                    <input 
                                        type='text' 
                                        name='itemCode' 
                                        placeholder='Enter Product Code'
                                        value={formData.itemCode}
                                        onChange={changeHandler}
                                    />
                                    {
                                        isMobile && 
                                        <div className='barcode-scanner-btn' onClick={()=>(setBarScanner(true))}>
                                            <IoMdBarcode/>
                                        </div>
                                    }    
                                </div>
                                <div className='form-item'>
                                    <label htmlFor='quantity'>Quantity<sup>*</sup></label>
                                    <input 
                                        type='text' 
                                        name='quantity' 
                                        placeholder='Enter Quantity'
                                        value={formData.quantity}
                                        onChange={changeHandler}
                                    />
                                </div>
                            </div>
                            <div className='form-items-container'>
                                <div className='form-item'>
                                    <label htmlFor='costPrice'>Cost Price<sup>*</sup></label>
                                    <input 
                                        type='text' 
                                        name='costPrice' 
                                        placeholder='Enter cost price'
                                        value={formData.costPrice}
                                        onChange={changeHandler}
                                    />
                                </div>
                                <div className='form-item'>
                                    <label htmlFor='sellingPrice'>Selling Price<sup>*</sup></label>
                                    <input 
                                        type='text' 
                                        name='sellingPrice' 
                                        placeholder='Enter selling price'
                                        value={formData.sellingPrice}
                                        onChange={changeHandler}
                                    />
                                </div>
                            </div>
                            <div className='form-items-container'>
                                <div className='form-item'>
                                    <label htmlFor='mrp'>MRP<sup>*</sup></label>
                                    <input 
                                        type='text' 
                                        name='mrp' 
                                        placeholder='Enter MRP'
                                        value={formData.mrp}
                                        onChange={changeHandler}
                                    />
                                </div>
                                <div className='form-item'>
                                    <label htmlFor='flag'>Flag<sup>*</sup></label>
                                    <select  
                                    name='flag'
                                    value={formData.flag}
                                    onChange={changeHandler}
                                    >
                                        <option value='none'>None</option>
                                        <option value='new'>New</option>
                                        <option value='trending'>Trending</option>
                                        <option value='popular'>Popular</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-item">
                                <label htmlFor='thumbnailImage'>Select Product Image <sup>*</sup></label>
                                <input name='thumbnailImage' type='file' accept="image/*;capture=camera" ref={ref} onChange={handleImgSelect} hidden/>
                                <div className='cpp-btns'>
                                    <button type='button' className='cpp-sec-btn' onClick={() => (ref.current.click())}>
                                        Select
                                    </button>
                                </div>
                            </div>
                            {
                                imgPreview ? <img src={imgPreview} className='preview-img' alt='profile_picture' /> : ''
                            }
                            <button type='submit' className='form-btn' disabled={productLoader ? true : false}>
                                Add Product
                            </button>
                        </div>
                    </form>
                </div>
                {
                productLoader &&
                <div className='loader-overlay'>
                    <div className="dots"></div>
                </div>
                }
                {
                    barScanner &&
                    <div className='barcode-scanner-container'>
                        <IoIosCloseCircle onClick={()=>setBarScanner(false)} className='barcode-scanner-close-btn'/>
                        <div className='barcode-scanner'>
                            <BarcodeScanner onCapture={(barcode)=>{setBarScanner(false); setFormData((prev) => ({   ...prev,itemCode:barcode.rawValue}));}} options={{formats:['ean_13','ean_8','upc_a','upc_e'],delay:500}}/>
                        </div>
                        <div>Scan Bar Code</div>
                    </div>
                }
            </div>
        </div>
    );
}

export default AddProduct;