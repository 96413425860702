import React, { useEffect, useRef, useState } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import '../../../css/Product.css';
import { AiOutlineDelete, AiOutlineEdit, AiOutlineEye,  AiOutlineSearch } from 'react-icons/ai';
import {BsFilterLeft, BsImageFill} from 'react-icons/bs';
import {FaAngleDown, FaAngleUp} from 'react-icons/fa';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import ModalAlert from '../common/ModalAlert';
import { addToList, deleteListProduct, deleteProduct } from '../../../services/operations/productOperations';
import { useNavigate } from 'react-router-dom';
import { setProductToEdit } from '../../../slices/productSlice';
import { Skeleton } from '@mui/material';
import {FilePathCon} from '../../../constant';
import { ACCOUNT_TYPE } from '../../../utils/constants';
import { MdOutlinePlaylistAdd , MdOutlinePlaylistAddCheck} from "react-icons/md";
import { VscVmActive } from "react-icons/vsc";
import { BarcodeScanner } from 'react-barcode-scanner';
import { IoIosCloseCircle, IoMdBarcode } from 'react-icons/io';
import { isDesktop, isMobile } from 'react-device-detect';

function AllProducts() {
    const createArray =(N) => {
        let newArr = [];
        for (let i = 1; i <= N; i++) {
            newArr.push(i);
        }
        return newArr;
    }
    const skeletonCount = createArray(12);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {token} = useSelector((state)=>(state.auth));
    const {user} = useSelector((state) => (state.profile));
    const {allProducts,productCategories,productLoader,myLoader,myList} = useSelector((state)=>(state.product));
    const [barScanner,setBarScanner] = useState(false);
    const [showCostPrice,setShowCostPrice] =useState([]);
    const [noOfItemsToShow,setNoOfItemsToShow] = useState(12);
    const [categoryToShow,setCategoryToShow] = useState(productCategories.map((category) => (category._id)));
    const [productsToShow,setProductsToShow] = useState(allProducts.toSorted((a, b) => a.title.localeCompare(b.title)));
    const [flagToShow,setFlagToShow] = useState(["trending","popular","new","none"]);
    const [stockToShow,setStockToShow] = useState(["inStock","outOfStock"]);
    const [listingToShow,setListingToShow] = useState(["active","inactive"]);
    const [searchStr,setSearchStr] = useState('');
    const [modal,setModal] = useState(false);
    const [showFilters,setShowFilters] = useState(false);
    const [smallShowFilters,setSmallShowFilters] = useState(false);
    const [deleteProductId,setDeleteProductId] =useState('');
    const [imgLoaded,setImgLoaded] = useState([]);
    const addToShowCP = (id) => {
        setShowCostPrice([
                ...showCostPrice,id
            ])
    }
    const removeFromShowCP = (id) => {
        setShowCostPrice(
                showCostPrice.filter((pid) => (pid !== id))
            )
    }
    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const [categoryDD,setCategoryDD] = useState(false);
    const [flagDD,setFlagDD] = useState(false);
    const [stockDD,setStockDD] = useState(false);
    const [listingDD,setListingDD] = useState(false);
    const [categorySF,setCategorySF] = useState(true);
    const [flagSF,setFlagSF] = useState(false);
    const [stockSF,setStockSF] = useState(false);
    const [listingSF,setListingSF] = useState(false);
    const categoryDDsetter = () => {
        setCategoryDD(!categoryDD);
        setFlagDD(false);
        setStockDD(false);
        setListingDD(false);
    };
    const flagDDsetter = () => {
        setCategoryDD(false);
        setFlagDD(!flagDD);
        setStockDD(false);
        setListingDD(false);
    }
    const stockDDsetter = () => {
        setCategoryDD(false);
        setFlagDD(false);
        setStockDD(!stockDD);
        setListingDD(false);
    }
    const listingDDsetter = () => {
        setCategoryDD(false);
        setFlagDD(false);
        setStockDD(false);
        setListingDD(!listingDD);
    }
    const categorySFsetter = () => {
        setCategorySF(true);
        setFlagSF(false);
        setStockSF(false);
        setListingSF(false);
    };
    const flagSFsetter = () => {
        setCategorySF(false);
        setFlagSF(true);
        setStockSF(false);
        setListingSF(false);
    }
    const stockSFsetter = () => {
        setCategorySF(false);
        setFlagSF(false);
        setStockSF(true);
        setListingSF(false);
    }
    const listingSFsetter = () => {
        setCategorySF(false);
        setFlagSF(false);
        setStockSF(false);
        setListingSF(true);
    }
    const ref=useRef(null);
    useOnClickOutside(ref, () => {
        setCategoryDD(false);
        setFlagDD(false);
        setStockDD(false);
        setListingDD(false);
        setSmallShowFilters(false);
    });
    const filterCategory = (event,id) => {
        if(event.target.checked){
            setCategoryToShow((prev)=>[...prev, id])
        }
        else
        {   
            setCategoryToShow(categoryToShow.filter((pid) => (pid !== id)))
        }
    }
    const filterFlag = (event,str) => {
        if(event.target.checked){
            setFlagToShow((prev)=>[...prev, str])
        }
        else
        {   
            setFlagToShow(flagToShow.filter((estr) => (estr !== str)))
        }
    }
    const filterStock = (event,str) => {
        if(event.target.checked){
            setStockToShow((prev)=>[...prev, str])
        }
        else
        {   
            setStockToShow(stockToShow.filter((estr) => (estr !== str)))
        }
    }
    const filterListing = (event,str) => {
        if(event.target.checked){
            setListingToShow((prev)=>[...prev, str])
        }
        else
        {   
            setListingToShow(listingToShow.filter((estr) => (estr !== str)))
        }
    }
    const handleSelectAllCategory = (event) => {
        if(event.target.checked){
            setCategoryToShow(productCategories.map((category) => (category._id)));
        }
        else
        {
            setCategoryToShow([]);
        }
    }
    const handleSelectAllFlag = (event) => {
        if(event.target.checked){
            setFlagToShow(["trending","popular","new","none"]);
        }
        else
        {
            setFlagToShow([]);
        }
    }
    const handleSelectAllStock = (event) => {
        if(event.target.checked){
            setStockToShow(["inStock","outOfStock"]);
        }
        else
        {
            setStockToShow([]);
        }
    }
    const handleSelectAllListing = (event) => {
        if(event.target.checked){
            setListingToShow(["active","inactive"]);
        }
        else
        {
            setListingToShow([]);
        }
    }
    const handleSearchStr = (event) => {
        setSearchStr(event.target.value);
    }
    useEffect(()=>{
        if(searchStr !== '') {
            setProductsToShow(allProducts.filter((product) => (categoryToShow.includes(product.productCategory) && flagToShow.includes(product.flag) 
            && stockToShow.includes(product.stockStatus) && listingToShow.includes(product.listingStatus) && ((searchStr.toLowerCase().trim().split(' ').every((str) => (product.title.toLowerCase().trim().includes(str) ))) || (searchStr.toLowerCase().trim().split(' ').every((str) => (product.description.toLowerCase().trim().includes(str) ))) || (searchStr.toLowerCase().trim().split(' ').every((str) => (product.itemCode.toLowerCase().trim().includes(str) ))) ))).toSorted((b, a) => a.updatedAt.localeCompare(b.updatedAt)).toSorted((a) => {if (a.flag === "none"){ return 1;}else {return -1;}}).toSorted((a, b) => {if(a.flag !== 'none' && b.flag !== 'none' ){ return b.updatedAt.localeCompare(a.updatedAt)}else{return 0}}).toSorted((a, b) => a.listingStatus.localeCompare(b.listingStatus)).toSorted((a, b) => a.stockStatus.localeCompare(b.stockStatus)))
        }
        else
        {
            setProductsToShow(allProducts.filter((product) => (categoryToShow.includes(product.productCategory) && flagToShow.includes(product.flag) 
        && stockToShow.includes(product.stockStatus) && listingToShow.includes(product.listingStatus)  )).toSorted((a, b) => b.updatedAt.localeCompare(a.updatedAt)).toSorted((a) => {if (a.flag === "none"){ return 1;}else {return -1;}}).toSorted((a, b) => {if(a.flag !== 'none' && b.flag !== 'none' ){ return b.updatedAt.localeCompare(a.updatedAt)}else{return 0}}).toSorted((a, b) => a.listingStatus.localeCompare(b.listingStatus)).toSorted((a, b) => a.stockStatus.localeCompare(b.stockStatus)))
        }
        
    },[categoryToShow,flagToShow,stockToShow,allProducts,searchStr,listingToShow])
    return (
        <div className='page-container' style={{position:"relative"}}>
            <div className='dark-block'>
                <div className='search-filter-container'>
                    <div className='search-product'>
                        <input type='text' placeholder='Search Product' value={searchStr} onChange={handleSearchStr} spellCheck="false" />
                        
                        {
                            isDesktop && <AiOutlineSearch/>
                        }    
                        
                        {
                            isMobile && 
                                <IoMdBarcode onClick={()=>(setBarScanner(true))}/>
                        }    
                    </div>
                    <div className='product-filters' ref={ref}>
                        <BsFilterLeft className='filter-btn' onClick={()=>(setShowFilters(!showFilters))}/>
                        <BsFilterLeft className='small-filter-btn' onClick={()=>(setSmallShowFilters(!smallShowFilters))}/>
                        <div className="filters-container">
                            <div className='filter-item'>
                                <span className="filter-type" onClick={categoryDDsetter}>Category {categoryDD ? <FaAngleUp/> : <FaAngleDown/>}</span>
                                {
                                    categoryDD &&
                                    <div className='filter-item-options-container'>
                                        <label className='filter-item-option'>
                                            <input type='checkbox' onChange={handleSelectAllCategory} checked ={categoryToShow.length === productCategories.length ? true :false}/>  All
                                            <div className='custom-checkbox'></div>
                                        </label>
                                        {
                                            productCategories.map((category)=>(
                                                <label className='filter-item-option' key={category._id}>
                                                    <input type='checkbox' onChange={(event) => {filterCategory(event,category._id);}} checked ={ categoryToShow.some((id) => (id === category._id)) ? true : false}/>  {category.title}
                                                    <div className='custom-checkbox'></div>
                                                </label>
                                            ))
                                        }
                                    </div>
                                }
                            </div>
                            <div className='filter-item'>
                                <span className="filter-type" onClick={flagDDsetter}>Flag {flagDD ? <FaAngleUp/> : <FaAngleDown/>}</span>
                                {
                                    flagDD &&
                                    <div className='filter-item-options-container'>
                                        <label className='filter-item-option'>
                                            <input type='checkbox' onChange={handleSelectAllFlag} checked ={flagToShow.length === 4 ? true :false}/>  All
                                            <div className='custom-checkbox'></div>
                                        </label>
                                        <label className='filter-item-option'>
                                            <input type='checkbox' onChange={(event) => (filterFlag(event,"none"))} checked ={ flagToShow.some((str) => (str === "none")) ? true : false}/>  No Flag
                                            <div className='custom-checkbox'></div>
                                        </label>
                                        <label className='filter-item-option'>
                                            <input type='checkbox' onChange={(event) => (filterFlag(event,"trending"))} checked ={ flagToShow.some((str) => (str === "trending")) ? true : false}/>  Trending
                                            <div className='custom-checkbox'></div>
                                        </label>
                                        <label className='filter-item-option'>
                                            <input type='checkbox' onChange={(event) => (filterFlag(event,"popular"))} checked ={ flagToShow.some((str) => (str === "popular")) ? true : false}/>  Popular
                                            <div className='custom-checkbox'></div>
                                        </label>
                                        <label className='filter-item-option'>
                                            <input type='checkbox' onChange={(event) => (filterFlag(event,"new"))} checked ={ flagToShow.some((str) => (str === "new")) ? true : false}/>  New
                                            <div className='custom-checkbox'></div>
                                        </label>
                                    </div>
                                }
                            </div>
                            <div className='filter-item'>
                                <span className="filter-type" onClick={stockDDsetter}>Stock {stockDD ? <FaAngleUp/> : <FaAngleDown/>}</span>
                                {
                                    stockDD &&
                                    <div className='filter-item-options-container'>
                                        <label className='filter-item-option'>
                                            <input type='checkbox' onChange={handleSelectAllStock} checked ={stockToShow.length === 2 ? true :false}/>  All
                                            <div className='custom-checkbox'></div>
                                        </label>
                                        <label className='filter-item-option'>
                                            <input type='checkbox' onChange={(event) => (filterStock(event,"inStock"))} checked ={ stockToShow.some((str) => (str === "inStock")) ? true : false}/>  In Stock
                                            <div className='custom-checkbox'></div>
                                        </label>
                                        <label className='filter-item-option'>
                                            <input type='checkbox' onChange={(event) => (filterStock(event,"outOfStock"))} checked ={ stockToShow.some((str) => (str === "outOfStock")) ? true : false}/>  Out of Stock
                                            <div className='custom-checkbox'></div>
                                        </label>
                                    </div>
                                }
                            </div>
                            <div className='filter-item'>
                                <span className="filter-type" onClick={listingDDsetter}>Listing {listingDD ? <FaAngleUp/> : <FaAngleDown/>}</span>
                                {
                                    listingDD &&
                                    <div className='filter-item-options-container'>
                                        <label className='filter-item-option'>
                                            <input type='checkbox' onChange={handleSelectAllListing} checked ={listingToShow.length === 2 ? true :false}/>  All
                                            <div className='custom-checkbox'></div>
                                        </label>
                                        <label className='filter-item-option'>
                                            <input type='checkbox' onChange={(event) => (filterListing(event,"active"))} checked ={ listingToShow.some((str) => (str === "active")) ? true : false}/>  Active
                                            <div className='custom-checkbox'></div>
                                        </label>
                                        <label className='filter-item-option'>
                                            <input type='checkbox' onChange={(event) => (filterListing(event,"inactive"))} checked ={ listingToShow.some((str) => (str === "inactive")) ? true : false}/>  Inactive
                                            <div className='custom-checkbox'></div>
                                        </label>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                { 
                myLoader ?
                <div className='products-container'>
                    {   
                        skeletonCount.map(
                            (count) => (
                                <div className='product-tile' key={count}>
                                    <div className="product-image-container">
                                    <Skeleton
                                        sx={{ bgcolor: 'rgba(22,29,41,0.9)' }}
                                        variant="rounded"
                                        animation="wave"
                                        width={260}
                                        height={220}
                                    />
                                    <BsImageFill style={{color:'#838894',position:'absolute',fontSize:'1.5rem'}}/>
                                    
                                    </div>
                                    <div className='product-title'><Skeleton variant="rounded" height={20} sx={{ fontSize: '1rem',bgcolor: 'rgba(0,8,20,0.9)'}}/></div>
                                    <div className='price-details'>
                                        <div className='selling-price'>
                                        <Skeleton variant="rounded" height={20} width={35} sx={{ fontSize: '1rem',bgcolor: 'rgba(0,8,20,0.9)'}}/>
                                        <Skeleton variant="rounded" height={15} width={80} sx={{ fontSize: '1rem',bgcolor: 'rgba(0,8,20,0.9)'}}/>
                                        </div>
                                        <div className='cpss-col'>
                                            <Skeleton variant="rounded" height={20} width={35} sx={{ fontSize: '1rem',bgcolor: 'rgba(0,8,20,0.9)'}}/>
                                            <div className='stock-status'>
                                            <Skeleton variant="rounded" height={15} width={60} sx={{ fontSize: '1rem',bgcolor: 'rgba(0,8,20,0.9)'}}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        )
                    }
                </div> :
                <div className='products-container'>
                    {   
                        productsToShow.length > 0 ?
                        productsToShow.slice(0,noOfItemsToShow).map(
                            (product) => (
                                <div className='product-tile' key={product._id}>
                                    <div className="product-image-container">
                                        {
                                            !imgLoaded.includes(product._id) && 
                                            <>
                                                <Skeleton
                                                sx={{ bgcolor: 'rgba(22,29,41,0.9)' }}
                                                variant="rounded"
                                                animation="wave"
                                                width={260}
                                                height={220}
                                                />
                                                <BsImageFill style={{color:'#838894',position:'absolute',fontSize:'1.5rem'}}/>
                                            </>
                                        }
                                        <img src={FilePathCon + product.thumbnail} style={!imgLoaded.includes(product._id) ? {display:'none'} : {display:'block'}} alt='img' className='product-image' onLoad={()=>(setImgLoaded((prev)=>[...prev,product._id]))} />
                                        {product.listingStatus === 'active' && <div className='listing-icon'><VscVmActive/></div>}
                                    </div>
                                    <div className='product-title'>{product.description}</div>
                                    <div className='price-details'>
                                        <div className='selling-price'>
                                            &#8377; {product.sellingPrice}
                                            {
                                                parseInt(product.sellingPrice) < parseInt(product.mrp) ?
                                                <div className='mrp-container'>
                                                    <span>MRP: <del>&#8377; {product.mrp}</del></span>
                                                    &nbsp;
                                                    <span className='off-on-mrp'>
                                                        {Math.round((parseInt(product.mrp)-parseInt(product.sellingPrice))*100/parseInt(product.mrp))}
                                                        &#37; off
                                                    </span>
                                                </div> 
                                                : 
                                                ''
                                            }
                                        </div>
                                        <div className='cpss-col'>
                                            {
                                                showCostPrice.find((id)=>(id === product._id)) ?
                                                <span onClick={() => (removeFromShowCP(product._id))}>&#8377;{product.costPrice}</span> : 
                                                <AiOutlineEye onClick={() => (addToShowCP(product._id))}/>
                                            }
                                            <div className='stock-status'>
                                                {
                                                    product.stockStatus === 'inStock' ? 
                                                    <span className='in-stock'>
                                                        {product.quantity} in Stock
                                                    </span> :
                                                    <span className='out-stock'>
                                                        Out of Stock
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                        {
                                            product.flag !== 'none' ? 
                                            <div className='product-flag'>{capitalizeFirstLetter(product.flag)}</div>
                                            :''
                                        }
                                        <div className='edit-delete-product'>
                                            {
                                                myList.some((prod) => (prod._id === product._id)) ?
                                                <button className='edit-product-btn' style={{color:'#FFD60A',fontSize:'1.1rem'}} onClick={() => {dispatch(deleteListProduct(token,product._id,setModal));}}><MdOutlinePlaylistAddCheck/></button>
                                                :
                                                <button className='edit-product-btn' style={{fontSize:'1.1rem'}} onClick={() => {dispatch(addToList(token,product._id));}}><MdOutlinePlaylistAdd/></button>
                                            }
                                            <button className='edit-product-btn' onClick={() => {dispatch(setProductToEdit(product));navigate('/dashboard/edit-product')}}><AiOutlineEdit/></button>
                                            {
                                                (user.accountType === ACCOUNT_TYPE.ADMIN) &&
                                                <button className='edit-product-btn' onClick={() => {setModal(true);setDeleteProductId(product._id)}}><AiOutlineDelete/></button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        ) : <div className='not-found'>No Product Found</div>
                    }

                </div>
                }
                {
                    (productsToShow.length > noOfItemsToShow) &&
                    <div className='show-more-container'>
                        <button type='button' className='form-btn show-more' onClick={()=>(setNoOfItemsToShow(noOfItemsToShow + 12))}>Show More</button>
                    </div>
                }
            </div>
            {
                smallShowFilters &&
                <div className="small-filters-container" ref={ref}>
                    <div className='filter-types-container'>
                        <span className={categorySF ? "filter-type active-filter-type" : "filter-type"} onClick={categorySFsetter}>Category</span>
                        <span className={flagSF ? "filter-type active-filter-type" : "filter-type"} onClick={flagSFsetter}>Flag </span>
                        <span className={stockSF ? "filter-type active-filter-type" : "filter-type"} onClick={stockSFsetter}>Stock</span>
                        <span className={listingSF ? "filter-type active-filter-type" : "filter-type"} onClick={listingSFsetter}>Listing</span>
                    </div>
                    <div className='filter-item-options-container'>
                        {
                            categorySF &&
                            <>
                                <label className='filter-item-option'>
                                    <input type='checkbox' onChange={handleSelectAllCategory} checked ={categoryToShow.length === productCategories.length ? true :false}/>  All
                                    <div className='custom-checkbox'></div>
                                </label>
                                {
                                    productCategories.map((category)=>(
                                        <label className='filter-item-option' key={category._id}>
                                            <input type='checkbox' onChange={(event) => {filterCategory(event,category._id);}} checked ={ categoryToShow.some((id) => (id === category._id)) ? true : false}/>  {category.title}
                                            <div className='custom-checkbox'></div>
                                        </label>
                                    ))
                                }
                            </>
                        }
                        {
                            flagSF && 
                            <>
                                <label className='filter-item-option'>
                                    <input type='checkbox' onChange={handleSelectAllFlag} checked ={flagToShow.length === 4 ? true :false}/>  All
                                    <div className='custom-checkbox'></div>
                                </label>
                                <label className='filter-item-option'>
                                    <input type='checkbox' onChange={(event) => (filterFlag(event,"none"))} checked ={ flagToShow.some((str) => (str === "none")) ? true : false}/>  No Flag
                                    <div className='custom-checkbox'></div>
                                </label>
                                <label className='filter-item-option'>
                                    <input type='checkbox' onChange={(event) => (filterFlag(event,"trending"))} checked ={ flagToShow.some((str) => (str === "trending")) ? true : false}/>  Trending
                                    <div className='custom-checkbox'></div>
                                </label>
                                <label className='filter-item-option'>
                                    <input type='checkbox' onChange={(event) => (filterFlag(event,"popular"))} checked ={ flagToShow.some((str) => (str === "popular")) ? true : false}/>  Popular
                                    <div className='custom-checkbox'></div>
                                </label>
                                <label className='filter-item-option'>
                                    <input type='checkbox' onChange={(event) => (filterFlag(event,"new"))} checked ={ flagToShow.some((str) => (str === "new")) ? true : false}/>  New
                                    <div className='custom-checkbox'></div>
                                </label>
                            </>
                        }
                        {
                            stockSF && 
                            <>
                                <label className='filter-item-option'>
                                    <input type='checkbox' onChange={handleSelectAllStock} checked ={stockToShow.length === 2 ? true :false}/>  All
                                    <div className='custom-checkbox'></div>
                                </label>
                                <label className='filter-item-option'>
                                    <input type='checkbox' onChange={(event) => (filterStock(event,"inStock"))} checked ={ stockToShow.some((str) => (str === "inStock")) ? true : false}/>  In Stock
                                    <div className='custom-checkbox'></div>
                                </label>
                                <label className='filter-item-option'>
                                    <input type='checkbox' onChange={(event) => (filterStock(event,"outOfStock"))} checked ={ stockToShow.some((str) => (str === "outOfStock")) ? true : false}/>  Out of Stock
                                    <div className='custom-checkbox'></div>
                                </label>
                            </>
                        }
                        {
                            listingSF &&
                            <>
                                <label className='filter-item-option'>
                                    <input type='checkbox' onChange={handleSelectAllListing} checked ={listingToShow.length === 2 ? true :false}/>  All
                                    <div className='custom-checkbox'></div>
                                </label>
                                <label className='filter-item-option'>
                                    <input type='checkbox' onChange={(event) => (filterListing(event,"active"))} checked ={ listingToShow.some((str) => (str === "active")) ? true : false}/>  Active
                                    <div className='custom-checkbox'></div>
                                </label>
                                <label className='filter-item-option'>
                                    <input type='checkbox' onChange={(event) => (filterListing(event,"inactive"))} checked ={ listingToShow.some((str) => (str === "inactive")) ? true : false}/>  Inactive
                                    <div className='custom-checkbox'></div>
                                </label>
                            </>
                        }
                    </div>
                    <button type='button' className='form-btn' onClick={()=>(setSmallShowFilters(false))}>
                        Apply
                    </button>
                </div>
            }
            {
                modal &&
                <ModalAlert
                    txt1 = 'Are you sure ?'
                    txt2 = 'This Product will be deleted permanently'
                    btn1txt = 'Delete'
                    btn2txt = 'Cancel'
                    handler1 = {() => dispatch(deleteProduct(token,deleteProductId,setModal))}
                    handler2 = {() => {setModal(false);setDeleteProductId('')}}
                />
            }
            {
                barScanner &&
                <div className='barcode-scanner-container'>
                    <IoIosCloseCircle onClick={()=>setBarScanner(false)} className='barcode-scanner-close-btn'/>
                    <div className='barcode-scanner'>
                        <BarcodeScanner onCapture={(barcode)=>{setBarScanner(false); setSearchStr(barcode.rawValue)}} options={{formats:['ean_13','ean_8','upc_a','upc_e'],delay:500}}/>
                    </div>
                    <div>Scan Bar Code</div>
                </div>
            }
            {
                productLoader &&
                <div className='loader-overlay'>
                    <div className="dots"></div>
                </div>
            }
        </div>
    );
}

export default AllProducts;