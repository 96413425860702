import React, { useState } from 'react';
import '../../../css/Auth.css';
import { useNavigate } from 'react-router-dom';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { setSignUpData } from '../../..//slices/authSlice';
import { sendOtpAdmin } from '../../../services/operations/authOperations';
import toast from 'react-hot-toast';

function AddUser() {
    const {token} = useSelector((state)=>(state.auth));
    const [showPassword,setShowPassword] = useState(false);
    const [showConfirmPassword,setShowConfirmPassword] = useState(false);
    const [formData , setFormData] = useState({
        firstName:'',lastName:'',email:'',password:'',confirmPassword:'',accountType:'Consumer'
    })
    const dispatch=useDispatch();
    const {authLoader} = useSelector((state)=>(state.auth));
    const navigate = useNavigate();

    const changeHandler = (event) => {
        setFormData((prev) => (
            {
                ...prev,[event.target.name]:event.target.value
            }
        ));
    }
    const submitHandler = (event) => {
        event.preventDefault();
        if(!formData.firstName.trim()){
            return toast.error('First Name is Required');
        }
        if(!formData.lastName.trim()){
            return toast.error('Last Name is Required');
        }
        if(!formData.email.trim()){
            return toast.error('Email is Required');
        }
        if(!/\S+@\S+\.\S+/.test(formData.email)){
            return toast.error('Enter a valid Email');
        }
        if(!formData.password){
            return toast.error('Password is Required');
        }
        if(formData.password.length < 6 || formData.password.length > 10){
            return toast.error('Password Length should 6-10 chars');
        }
        if(!formData.confirmPassword){
            return toast.error('Confirm Password is Required');
        }
        if(formData.password !== formData.confirmPassword)
        {
            return toast.error('Passwords do not match');
        }
        
        dispatch(setSignUpData(formData));
        dispatch(sendOtpAdmin(formData.email,token,navigate));
    }

    return (
        <div className='page-container'>
            <div className='dark-block'>
                <div className='auth-page add-user'>
                    <form className='auth-form-container' onSubmit={submitHandler} autoComplete='off'>
                        <div className='main-title'>
                            User Creation Form
                        </div>
                        <div className='main-des'>
                            Add a user with required details and assign a role.
                            <span className='highlight-des'></span>
                        </div>
                        <div className='radio-btn'>
                            <label>
                                <input
                                type='radio'
                                name='accountType'
                                value='Consumer'
                                onChange={changeHandler}
                                defaultChecked/>
                                <span>Consumer</span>
                            </label>
                            <label>
                                <input
                                type='radio'
                                name='accountType'
                                value='Superuser'
                                onChange={changeHandler}
                                />
                                <span>Superuser</span>
                            </label>
                        </div>
                        <div className='auth-form'>
                            <div className='form-items-container'>
                                <div className='form-item'>
                                    <label htmlFor='firstName'>First Name <sup>*</sup></label>
                                    <input 
                                        type='text' 
                                        name='firstName' 
                                        placeholder='Enter first name'
                                        value={formData.firstName}
                                        onChange={changeHandler}
                                    />
                                </div>
                                <div className='form-item'>
                                    <label htmlFor='lastName'>Last Name <sup>*</sup></label>
                                    <input 
                                        type='text' 
                                        name='lastName' 
                                        placeholder='Enter last name'
                                        value={formData.lastName}
                                        onChange={changeHandler}
                                    />
                                </div>
                            </div>
                            <div className='form-item'>
                                <label htmlFor='email'>Email Address <sup>*</sup></label>
                                <input 
                                    type='text' 
                                    name='email' 
                                    placeholder='Enter email address'
                                    value={formData.email}
                                    onChange={changeHandler}
                                />
                            </div>
                            <div className='form-items-container'>
                                <div className='form-item'>
                                    <label htmlFor='password'>Create Password <sup>*</sup></label>
                                    <input 
                                        type={showPassword ? 'text' : 'password'} 
                                        name='password' 
                                        placeholder='Enter Password'
                                        value={formData.password}
                                        onChange={changeHandler}   
                                    />
                                    <div className='eye-btn' onClick={()=>(setShowPassword((prev)=>(!prev)))}> 
                                        {showPassword ? <AiOutlineEyeInvisible/> : <AiOutlineEye/>} 
                                    </div>
                                </div>
                                <div className='form-item'>
                                    <label htmlFor='confirmPassword'>Confirm Password <sup>*</sup></label>
                                    <input 
                                        type={showConfirmPassword ? 'text' : 'password'}  
                                        name='confirmPassword' 
                                        placeholder='Enter Password'
                                        value={formData.confirmPassword}
                                        onChange={changeHandler}
                                    />
                                    <div className='eye-btn' onClick={()=>(setShowConfirmPassword((prev)=>(!prev)))}> 
                                        {showConfirmPassword ? <AiOutlineEyeInvisible/> : <AiOutlineEye/>} 
                                    </div>
                                </div>
                            </div>
                            <button type='submit' className='form-btn' disabled={authLoader ? true : false}>
                                Create Account
                            </button>
                        </div>
                    </form>
                </div>
                {
                    authLoader &&
                    <div className='loader-overlay'>
                        <div className="dots"></div>
                    </div>
                }
            </div>
        </div>
    );
}

export default AddUser;