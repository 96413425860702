import React from 'react';
import '../../../css/Footer.css';
import Logo from '../../../assets/Logo/Logo-Full-Light.png';
import { Link } from 'react-router-dom';
import {FaFacebook, FaGoogle, FaTwitter, FaYoutube} from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { IoIosMail } from "react-icons/io";
import { IoLogoWhatsapp } from "react-icons/io";
import ScrollToTop from 'react-scroll-to-top';

const BottomFooter = ["Privacy Policy", "Terms"];
const Company = ["About","Contact"]

function Footer() {
    const {productCategories} = useSelector((state) => (state.product));
    return (
        <>
        <div className='footer-container'>
            <div className='footer-links-section'>
                <div className='fl-column'>
                    <div className='footer-logo'>
                        <img src={Logo} alt='_logo'/>
                    </div>
                    <div className='footer-txt'>
                        Discover the best of what we offer - explore our products and shop with confidence
                    </div>
                    <Link className='footer-mail-to' to='mailto:support@rkeservices.in'>
                        <IoIosMail/> support@rkeservices.in
                    </Link>
                    <Link className='footer-mail-to' to='https://wa.me/918950722210' target='blank'>
                        <IoLogoWhatsapp/> Chat with Us
                    </Link>
                </div>
                <div className='fl-column'>
                <div className='links-by-title'>
                        <div className='lbt-title'>
                            Company
                        </div>
                        <div className='lbt-links'>
                            <Link to='/'>Home</Link>
                            {
                                Company.map((link,index)=>(
                                    <Link to={`/${link.split(" ").join('-').toLowerCase()}`} key={index}>{link}</Link>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className='fl-column'>
                    <div className='links-by-title'>
                        <div className='lbt-title'>
                            Catalog
                        </div>
                        <div className='lbt-links'>
                            {
                                    productCategories.length > 0 &&
                                    productCategories.map((category) => (
                                        <Link to={`/catalog/${category.title.split(' ').join('-').toLowerCase()}`} key={category._id} >
                                            {category.title}
                                        </Link>
                                    ))
                            }
                        </div>
                    </div>
                </div>
                <div className='fl-column'>
                    <div className='social-links'>
                        <Link to={'/'}><FaFacebook/></Link>
                        <Link to={'/'}><FaGoogle/></Link>
                        <Link to={'/'}><FaTwitter/></Link>
                        <Link to={'/'}><FaYoutube/></Link>
                    </div>
                </div>
            </div>
            <div className='footer-cr-section'>
                <div className='cr-links'>
                    {
                        BottomFooter.map((link,index) => {
                            return(
                                <div className='cr-link-item' key={index}>
                                    <Link to='/'>{link}</Link>
                                    {index < BottomFooter.length - 1 ? <div className='footer-separator'></div> : ""}
                                </div>  
                            )
                        }   
                        )
                    }
                </div>
                <div className='cr-txt'>
                    Developed by Ankur Nindwani &copy; 2024 RK eServices
                </div>
            </div>
        </div>
        <ScrollToTop 
            smooth
            viewBox="0 0 450 450"
            width='30'
            height='30'
            color='#FFD60A'
            svgPath="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"
        />
        </>
    );
}

export default Footer;