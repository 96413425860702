import toast from "react-hot-toast";
import { apiConnector } from "../apiConnector";
import { productEndpoints } from "../apis";
import { setProductCategories, setProductLoader , setAllProducts, setProductToEdit, setMyLoader, setMyList,setMyListLoader, setCategoryLoader } from "../../slices/productSlice";

const {SHOW_ALL_CATEGORIES,CREATE_CATEGORY,CREATE_PRODUCT,SHOW_ALL_PRODUCTS,
    DELETE_PRODUCT,EDIT_PRODUCT,ADD_TO_LIST,SUPER_USER_LIST,DELETE_FROM_SU_LIST,
    DELETE_ALL_SU_LIST,CATALOG_LIST,ADD_TO_WISHLIST,CONSUMER_WISHLIST,DELETE_FROM_WISHLIST,DELETE_ALL_WISHLIST,HOME_LIST} = productEndpoints

export function fetchCategories(){
    return async (dispatch) => {
        await apiConnector('GET',SHOW_ALL_CATEGORIES).then((response) =>{
            if(!response.data.success){
                throw new Error(response.data.message);
            }
            dispatch(setProductCategories(response.data.allCategories));
        })
        .catch((error) =>{
            toast.error("Unable to fetch Product Categories");
        }
        )
        dispatch(setCategoryLoader(false));
    }
}
export function fetchProducts(token){
    return async (dispatch) => {
        dispatch(setMyLoader(true));
        await apiConnector('GET',SHOW_ALL_PRODUCTS,null,null,{Authorization: `Bearer ${token}`}).then((response) =>{
            if(!response.data.success){
                throw new Error(response.data.message);
            }
            dispatch(setAllProducts(response.data.data));
        })
        .catch((error) =>{
            toast.error("Unable to fetch Products");
        }
        )
        dispatch(setMyLoader(false));
    }
}
export function fetchListProducts(token){
    return async (dispatch) => {
        dispatch(setMyListLoader(true));
        await apiConnector('GET',SUPER_USER_LIST,null,null,{Authorization: `Bearer ${token}`}).then((response) =>{
            if(!response.data.success){
                throw new Error(response.data.message);
            }
            dispatch(setMyList(response.data.data));
        })
        .catch((error) =>{
            toast.error("Unable to fetch Products");
        }
        )
        dispatch(setMyListLoader(false));
    }
}
export function fetchWishlistProducts(token){
    return async (dispatch) => {
        dispatch(setMyListLoader(true));
        await apiConnector('GET',CONSUMER_WISHLIST,null,null,{Authorization: `Bearer ${token}`}).then((response) =>{
            if(!response.data.success){
                throw new Error(response.data.message);
            }
            dispatch(setMyList(response.data.data));
        })
        .catch((error) =>{
            toast.error("Unable to fetch Products");
        }
        )
        dispatch(setMyListLoader(false));
    }
}
export function fetchCatalogProducts(catId,setCatalogList,setCatalogLoader){
    return async (dispatch) => {
        setCatalogLoader(true);
        await apiConnector('PUT',CATALOG_LIST,{catId}).then((response) =>{
            if(!response.data.success){
                throw new Error(response.data.message);
            }
            setCatalogList(response.data.data);
        })
        .catch((error) =>{
            toast.error("Unable to fetch Products");
        }
        )
        setCatalogLoader(false);
    }
}
export function fetchHomeProducts(catId,flag,number,setCatalogList,setCatalogLoader){
    return async (dispatch) => {
        setCatalogLoader(true);
        await apiConnector('PUT',HOME_LIST,{catId,flag,number}).then((response) =>{
            if(!response.data.success){
                throw new Error(response.data.message);
            }
            setCatalogList(response.data.data);
        })
        .catch((error) =>{
            toast.error("Unable to fetch Products");
        }
        )
        setCatalogLoader(false);
    }
}
export function createCategory(token,formData,setFormData,initialData){
    return async (dispatch) => {
        dispatch(setProductLoader(true));
        await apiConnector('POST',CREATE_CATEGORY,formData,null,{Authorization: `Bearer ${token}`})
        .then((response) =>{
            if(!response.data.success){
                throw new Error(response.data.message);
            }
            toast.success(response.data.message);
            setFormData(initialData);
            dispatch(fetchCategories());
        })
        .catch((error) =>{
            if(error.response){
                toast.error(error.response.data.message);
            }
            else if(error.request){
                toast.error('No response Recieved');
            }
            else
            {
                toast.error('Server Error');
            }
        }
        )
        dispatch(setProductLoader(false));
    }
}
export function createProduct(token,formData,setFormData,initialData,setImgPreview,setImgFile){
    return async (dispatch) => {
        dispatch(setProductLoader(true));
        await apiConnector('POST',CREATE_PRODUCT,formData,null,{"Content-Type": "multipart/form-data",Authorization: `Bearer ${token}`})
        .then((response) =>{
            if(!response.data.success){
                throw new Error(response.data.message);
            }
            toast.success(response.data.message);
            setFormData(initialData);
            setImgPreview(null);
            setImgFile(null);
            dispatch(fetchProducts(token));
        })
        .catch((error) =>{
            if(error.response){
                toast.error(error.response.data.message);
            }
            else if(error.request){
                toast.error('No response Recieved');
            }
            else
            {
                toast.error('Server Error');
            }
        }
        )
        dispatch(setProductLoader(false));
    }
}
export function editProduct(token,formData,navigate){
    return async (dispatch) => {
        dispatch(setProductLoader(true));
        await apiConnector('PUT',EDIT_PRODUCT,formData,null,{"Content-Type": "multipart/form-data",Authorization: `Bearer ${token}`})
        .then((response) =>{
            if(!response.data.success){
                throw new Error(response.data.message);
            }
            toast.success(response.data.message);
            dispatch(fetchProducts(token));
            dispatch(fetchListProducts(token));
            navigate('/dashboard/my-products');
            dispatch(setProductToEdit(null));
        })
        .catch((error) =>{
            if(error.response){
                toast.error(error.response.data.message);
            }
            else if(error.request){
                toast.error('No response Recieved');
            }
            else
            {
                toast.error('Server Error');
            }
        }
        )
        dispatch(setProductLoader(false));
    }
}
export function addToList(token,productId){
    return async (dispatch) => {
        dispatch(setProductLoader(true));
        await apiConnector('PUT',ADD_TO_LIST,{productId},null,{"Content-Type": "multipart/form-data",Authorization: `Bearer ${token}`})
        .then((response) =>{
            if(!response.data.success){
                throw new Error(response.data.message);
            }
            toast.success(response.data.message);
            dispatch(fetchListProducts(token));
        })
        .catch((error) =>{
            if(error.response){
                toast.error(error.response.data.message);
            }
            else if(error.request){
                toast.error('No response Recieved');
            }
            else
            {
                toast.error('Server Error');
            }
        }
        )
        dispatch(setProductLoader(false));
    }
}
export function addToWishlist(token,productId){
    return async (dispatch) => {
        dispatch(setProductLoader(true));
        await apiConnector('PUT',ADD_TO_WISHLIST,{productId},null,{"Content-Type": "multipart/form-data",Authorization: `Bearer ${token}`})
        .then((response) =>{
            if(!response.data.success){
                throw new Error(response.data.message);
            }
            toast.success(response.data.message);
            dispatch(fetchWishlistProducts(token));
        })
        .catch((error) =>{
            if(error.response){
                toast.error(error.response.data.message);
            }
            else if(error.request){
                toast.error('No response Recieved');
            }
            else
            {
                toast.error('Server Error');
            }
        }
        )
        dispatch(setProductLoader(false));
    }
}
export function deleteProduct(token,productId,setModal){
    return async (dispatch) => {
        dispatch(setProductLoader(true));
        setModal(false);
        await apiConnector('DELETE',DELETE_PRODUCT,{productId},null,{Authorization: `Bearer ${token}`}).then((response) =>{
            if(!response.data.success){
                throw new Error(response.data.message);
            }
            toast.success(response.data.message);
            dispatch(fetchProducts(token));
        })
        .catch((error) =>{
            if(error.response){
                toast.error(error.response.data.message);
            }
            else if(error.request){
                toast.error('No response Recieved');
            }
            else
            {
                toast.error('Server Error');
            }
        }
        )
        dispatch(setProductLoader(false));
    }
}
export function deleteListProduct(token,productId,setModal){
    return async (dispatch) => {
        dispatch(setProductLoader(true));
        setModal(false);
        await apiConnector('PUT',DELETE_FROM_SU_LIST,{productId},null,{Authorization: `Bearer ${token}`}).then((response) =>{
            if(!response.data.success){
                throw new Error(response.data.message);
            }
            toast.success(response.data.message);
            dispatch(fetchListProducts(token));
        })
        .catch((error) =>{
            if(error.response){
                toast.error(error.response.data.message);
            }
            else if(error.request){
                toast.error('No response Recieved');
            }
            else
            {
                toast.error('Server Error');
            }
        }
        )
        dispatch(setProductLoader(false));
    }
}
export function deleteWishlistProduct(token,productId,setModal){
    return async (dispatch) => {
        dispatch(setProductLoader(true));
        setModal(false);
        await apiConnector('PUT',DELETE_FROM_WISHLIST,{productId},null,{Authorization: `Bearer ${token}`}).then((response) =>{
            if(!response.data.success){
                throw new Error(response.data.message);
            }
            toast.success(response.data.message);
            dispatch(fetchWishlistProducts(token));
        })
        .catch((error) =>{
            if(error.response){
                toast.error(error.response.data.message);
            }
            else if(error.request){
                toast.error('No response Recieved');
            }
            else
            {
                toast.error('Server Error');
            }
        }
        )
        dispatch(setProductLoader(false));
    }
}
export function deleteAllSUList(token,setClModal){
    return async (dispatch) => {
        dispatch(setProductLoader(true));
        setClModal(false);
        await apiConnector('PUT',DELETE_ALL_SU_LIST,null,null,{Authorization: `Bearer ${token}`}).then((response) =>{
            if(!response.data.success){
                throw new Error(response.data.message);
            }
            toast.success(response.data.message);
            dispatch(fetchListProducts(token));
        })
        .catch((error) =>{
            if(error.response){
                toast.error(error.response.data.message);
            }
            else if(error.request){
                toast.error('No response Recieved');
            }
            else
            {
                toast.error('Server Error');
            }
        }
        )
        dispatch(setProductLoader(false));
    }
}
export function deleteAllWishlist(token,setClModal){
    return async (dispatch) => {
        dispatch(setProductLoader(true));
        setClModal(false);
        await apiConnector('PUT',DELETE_ALL_WISHLIST,null,null,{Authorization: `Bearer ${token}`}).then((response) =>{
            if(!response.data.success){
                throw new Error(response.data.message);
            }
            toast.success(response.data.message);
            dispatch(fetchWishlistProducts(token));
        })
        .catch((error) =>{
            if(error.response){
                toast.error(error.response.data.message);
            }
            else if(error.request){
                toast.error('No response Recieved');
            }
            else
            {
                toast.error('Server Error');
            }
        }
        )
        dispatch(setProductLoader(false));
    }
}

