import React from 'react';
import { useSelector } from 'react-redux';
import BtnComponent from '../home/BtnComponent';
import {FiEdit} from 'react-icons/fi';

function Profile() {

    const {userFullDetails} = useSelector((state) => (state.profile));
    const formattedDate = (date) => {
        return new Date(date).toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
        })
    }
    return (
        <div className='page-container'>
            <div className='dark-block'>
                <div className='profile-tiles-container'>
                    <div className='dashboard-page-title'>
                        My Profile
                        <div className='edit-btn'>
                            <BtnComponent linkto={'/dashboard/settings'} active={true}>
                                Edit <FiEdit/>
                            </BtnComponent>
                        </div>
                    </div>
                    <div className='profile-tile'>
                        <div className='profile-tile-header'>
                            <div className='user-details'>
                                <img src={userFullDetails.profilePicture} alt='profile_picture' />
                                <div className='user-namemail'>
                                    <div className='user-name'>
                                        {`${userFullDetails.firstName} ${userFullDetails.lastName}`}
                                    </div>
                                    <div className='user-email'>
                                        {userFullDetails.email}
                                    </div>
                                </div>
                            </div>
                            <div className='edit-btn'>
                                <BtnComponent linkto={'/dashboard/settings'} active={true}>
                                    Edit <FiEdit/>
                                </BtnComponent>
                            </div>
                        </div>
                    </div>
                    <div className='profile-tile'>
                        <div className='profile-tile-header'>
                            <div className='tile-name'>
                                About
                            </div>
                            <div className='edit-btn'>
                                <BtnComponent linkto={'/dashboard/settings'} active={true}>
                                    Edit <FiEdit/>
                                </BtnComponent>
                            </div>
                        </div>
                        <div className={userFullDetails.additionalDetails.about ? 'user-about-txt' : 'user-about-placeholder'}>
                            {userFullDetails.additionalDetails.about ? userFullDetails.additionalDetails.about : 'Write Something About Yourself'}
                        </div>
                    </div>
                    <div className='profile-tile'>
                        <div className='profile-tile-header'>
                            <div className='tile-name'>
                                Personal Details
                            </div>
                            <div className='edit-btn'>
                                <BtnComponent linkto={'/dashboard/settings'} active={true}>
                                    Edit <FiEdit/>
                                </BtnComponent>
                            </div>
                        </div>
                        <div className='personal-details'>
                            <div className='pd-col'>
                                <div className='pd-item'>
                                    <p className='pd-title'>First Name</p>
                                    <p className='pd-value'>{userFullDetails.firstName}</p>
                                </div>
                                <div className='pd-item'>
                                    <p className='pd-title'>Last Name</p>
                                    <p className='pd-value'>{userFullDetails.lastName}</p>
                                </div>
                                <div className='pd-item'>
                                    <p className='pd-title'>Email</p>
                                    <p className='pd-value'>{userFullDetails.email}</p>
                                </div>
                                
                            </div>
                            <div className='pd-col'>
                                <div className='pd-item'>
                                    <p className='pd-title'>Gender</p>
                                    <p className='pd-value'>{userFullDetails.additionalDetails.gender ? userFullDetails.additionalDetails.gender : "Add Gender"}</p>
                                </div>
                                <div className='pd-item'>
                                    <p className='pd-title'>Phone Number</p>
                                    <p className='pd-value'>{userFullDetails.additionalDetails.contactNumber ? userFullDetails.additionalDetails.contactNumber : "Add Contact Number"}</p>
                                </div>
                                <div className='pd-item'>
                                    <p className='pd-title'>Date Of Birth</p>
                                    <p className='pd-value'>{userFullDetails.additionalDetails.dateOfBirth ? formattedDate(userFullDetails.additionalDetails.dateOfBirth) : "January 1, 1970"}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;