import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { ACCOUNT_TYPE } from '../../../utils/constants';

function SURoute({children}) {
    const {user} = useSelector((state) => (state.profile));
    if(user.accountType === ACCOUNT_TYPE.SUPERUSER || user.accountType === ACCOUNT_TYPE.ADMIN){
        return children
    }
    else
    {
        return <Navigate to='/'/>
    }
}

export default SURoute;