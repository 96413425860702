import { combineReducers } from '@reduxjs/toolkit';

import authReducer from "../slices/authSlice";
import profileReducer from "../slices/profileSlice";
import sidebarReducer from "../slices/sidebarSlice";
import productReducer from "../slices/productSlice";

export const rootReducer = combineReducers({
    auth:authReducer,
    profile:profileReducer,
    sidebar:sidebarReducer,
    product:productReducer
})