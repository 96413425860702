import React from 'react';

function ModalAlert({txt1,txt2,btn1txt,btn2txt,handler1,handler2}) {
    return (
        <div className='modal-blur'>
            <div className='modal-box'>
                <div className='modal-txt1'>
                    {txt1}
                </div>
                <div className='modal-txt2'>
                    {txt2}
                </div>
                <div className='modal-btns-container'>
                    <button type='button' className='modal-prime-btn' onClick={handler1}>
                        {btn1txt}
                    </button>
                    <button type='button' className='modal-sec-btn' onClick={handler2}>
                        {btn2txt}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ModalAlert;