import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    token : localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null,
    authLoader:false,
    signUpData:null,
    loggedIn:false,
}

export const authSlice = createSlice({
    name:'auth',
    initialState:initialState,
    reducers:{
        setToken:(state,value) => {
            state.token = value.payload
        },
        setSignUpData:(state,value) => {
            state.signUpData = value.payload
        },
        setAuthLoader:(state,value) => {
            state.authLoader = value.payload
        },
        setLoggedIn:(state,value) => {
            state.loggedIn = value.payload
        }
    }
});

export const {setToken,setSignUpData,setAuthLoader,setLoggedIn} = authSlice.actions;
export default authSlice.reducer;

