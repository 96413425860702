import React, { useState } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import '../../../css/Product.css';
import { AiOutlineEdit, AiOutlineEye } from 'react-icons/ai';
import { BsImageFill} from 'react-icons/bs';
import ModalAlert from '../common/ModalAlert';
import { deleteAllSUList, deleteListProduct } from '../../../services/operations/productOperations';
import { Skeleton } from '@mui/material';
import {FilePathCon} from '../../../constant';
import { MdPlaylistRemove , MdOutlineFormatListBulleted } from "react-icons/md";
import { VscVmActive } from 'react-icons/vsc';
import { setProductToEdit } from '../../../slices/productSlice';
import { useNavigate } from 'react-router-dom';

function MyList() {
    const createArray =(N) => {
        let newArr = [];
        for (let i = 1; i <= N; i++) {
            newArr.push(i);
        }
        return newArr;
    }
    const skeletonCount = createArray(12);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {token} = useSelector((state)=>(state.auth));
    const {myList,productLoader,myListLoader} = useSelector((state)=>(state.product));
    const [showCostPrice,setShowCostPrice] =useState([]);
    const [noOfItemsToShow,setNoOfItemsToShow] = useState(12);
    const [modal,setModal] = useState(false);
    const [clModal,setClModal] = useState(false);
    const [deleteProductId,setDeleteProductId] =useState('');
    const [imgLoaded,setImgLoaded] = useState([]);
    const addToShowCP = (id) => {
        setShowCostPrice([
                ...showCostPrice,id
            ])
    }
    const removeFromShowCP = (id) => {
        setShowCostPrice(
                showCostPrice.filter((pid) => (pid !== id))
            )
    }
    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
        <div className='page-container' style={{position:"relative"}}>
            <div className='dark-block'>
                {
                    myList.length > 0 && !myListLoader &&
                    <div style={{width:'100%',display:'flex',flexDirection:'row-reverse',padding:'20px 20px 0 20px',justifyContent:'space-between',gap:'10px',alignItems:'center'}}>
                        <button type='button' className='clear-btn' onClick={()=>(setClModal(true))}><MdOutlineFormatListBulleted/> Clear List</button>
                        <span style={{color:'#DBDDEA',fontSize:'20px',fontWeight:'600'}}>My Products List</span>
                    </div>
                }
                { 
                myListLoader ?
                <div className='products-container'>
                    {   
                        skeletonCount.map(
                            (count) => (
                                <div className='product-tile' key={count}>
                                    <div className="product-image-container">
                                    <Skeleton
                                        sx={{ bgcolor: 'rgba(22,29,41,0.9)' }}
                                        variant="rounded"
                                        animation="wave"
                                        width={260}
                                        height={220}
                                    />
                                    <BsImageFill style={{color:'#838894',position:'absolute',fontSize:'1.5rem'}}/>
                                    
                                    </div>
                                    <div className='product-title'><Skeleton variant="rounded" height={20} sx={{ fontSize: '1rem',bgcolor: 'rgba(0,8,20,0.9)'}}/></div>
                                    <div className='price-details'>
                                        <div className='selling-price'>
                                        <Skeleton variant="rounded" height={20} width={35} sx={{ fontSize: '1rem',bgcolor: 'rgba(0,8,20,0.9)'}}/>
                                        <Skeleton variant="rounded" height={15} width={80} sx={{ fontSize: '1rem',bgcolor: 'rgba(0,8,20,0.9)'}}/>
                                        </div>
                                        <div className='cpss-col'>
                                            <Skeleton variant="rounded" height={20} width={35} sx={{ fontSize: '1rem',bgcolor: 'rgba(0,8,20,0.9)'}}/>
                                            <div className='stock-status'>
                                            <Skeleton variant="rounded" height={15} width={60} sx={{ fontSize: '1rem',bgcolor: 'rgba(0,8,20,0.9)'}}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        )
                    }
                </div> :
                <div className='products-container'>
                    {   
                        myList.length > 0 ?
                        myList.slice(0,noOfItemsToShow).map(
                            (product) => (
                                <div className='product-tile' key={product._id}>
                                    <div className="product-image-container">
                                        {
                                            !imgLoaded.includes(product._id) && 
                                            <>
                                                <Skeleton
                                                sx={{ bgcolor: 'rgba(22,29,41,0.9)' }}
                                                variant="rounded"
                                                animation="wave"
                                                width={260}
                                                height={220}
                                                />
                                                <BsImageFill style={{color:'#838894',position:'absolute',fontSize:'1.5rem'}}/>
                                            </>
                                        }
                                        <img src={FilePathCon + product.thumbnail} style={!imgLoaded.includes(product._id) ? {display:'none'} : {display:'block'}} alt='img' className='product-image' onLoad={()=>(setImgLoaded((prev)=>[...prev,product._id]))} />
                                        {product.listingStatus === 'active' && <div className='listing-icon'><VscVmActive/></div>}
                                    </div>
                                    <div className='product-title'>{product.description}</div>
                                    <div className='price-details'>
                                        <div className='selling-price'>
                                            &#8377; {product.sellingPrice}
                                            {
                                                parseInt(product.sellingPrice) < parseInt(product.mrp) ?
                                                <div className='mrp-container'>
                                                    <span>MRP: <del>&#8377; {product.mrp}</del></span>
                                                    &nbsp;
                                                    <span className='off-on-mrp'>
                                                        {Math.round((parseInt(product.mrp)-parseInt(product.sellingPrice))*100/parseInt(product.mrp))}
                                                        &#37; off
                                                    </span>
                                                </div> 
                                                : 
                                                ''
                                            }
                                        </div>
                                        <div className='cpss-col'>
                                            {
                                                showCostPrice.find((id)=>(id === product._id)) ?
                                                <span onClick={() => (removeFromShowCP(product._id))}>&#8377;{product.costPrice}</span> : 
                                                <AiOutlineEye onClick={() => (addToShowCP(product._id))}/>
                                            }
                                            <div className='stock-status'>
                                                {
                                                    product.stockStatus === 'inStock' ? 
                                                    <span className='in-stock'>
                                                    {product.quantity} in Stock
                                                    </span> :
                                                    <span className='out-stock'>
                                                        Out of Stock
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                        {
                                            product.flag !== 'none' &&
                                            <div className='product-flag'>{capitalizeFirstLetter(product.flag)}</div>
                                        }
                                        <div className='edit-delete-product'>
                                            <button className='edit-product-btn' onClick={() => {dispatch(setProductToEdit(product));navigate('/dashboard/edit-product')}}><AiOutlineEdit/></button>
                                            <button className='edit-product-btn'  style={{fontSize:'1.1rem'}} onClick={() => {setModal(true);setDeleteProductId(product._id)}}><MdPlaylistRemove/></button>
                                        </div>
                                    </div>
                                </div>
                            )
                        ) : <div className='not-found'>No Product Found</div>
                    }

                </div>
                }
                {
                    (myList.length > noOfItemsToShow) &&
                    <div className='show-more-container'>
                        <button type='button' className='form-btn show-more' onClick={()=>(setNoOfItemsToShow(noOfItemsToShow + 12))}>Show More</button>
                    </div>
                }
            </div>
            {
                modal &&
                <ModalAlert
                    txt1 = 'Are you sure ?'
                    txt2 = 'This Product will be deleted from List'
                    btn1txt = 'Delete'
                    btn2txt = 'Cancel'
                    handler1 = {() => dispatch(deleteListProduct(token,deleteProductId,setModal))}
                    handler2 = {() => {setModal(false);}}
                />
            }
            {
                clModal &&
                <ModalAlert
                    txt1 = 'Are you sure ?'
                    txt2 = 'All Products from your list will Cleared.'
                    btn1txt = 'Clear All'
                    btn2txt = 'Cancel'
                    handler1 = {() => dispatch(deleteAllSUList(token,setClModal))}
                    handler2 = {() => {setClModal(false);}}
                />
            }
            {
                productLoader &&
                <div className='loader-overlay'>
                    <div className="dots"></div>
                </div>
            }
        </div>
    );
}

export default MyList;