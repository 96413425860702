import React from 'react';
import Footer from '../components/core/common/Footer';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import '../css/Static.css';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Skeleton } from '@mui/material';

function CatalogHome() {
    const {productCategories,categoryLoader} = useSelector((state) => (state.product))
    return (
        <>
            <div className='page-container'>
                <div className="static-title-container">
                    <div className="static-title">
                        <div className='gradient-txt'>Product Categories</div>
                        <div className='small-border'></div>
                    </div>
                </div>
                <div className='dark-block'>
                    <div class="category-list-container">
                        <div className='category-list'>
                            {
                                !categoryLoader ? 
                                (
                                    productCategories.length > 0 ?
                                    productCategories.map((category) => (
                                        <div class="cl-item">
                                            <MdKeyboardArrowRight/>
                                            <Link to={`/catalog/${category.title.split(' ').join('-').toLowerCase()}`} key={category._id} >
                                                {category.title}
                                            </Link>
                                        </div>
                                    )):
                                    <div className='not-found'>No Category Found</div>
                                ):
                                <>
                                    <div className='cl-item'>
                                        <MdKeyboardArrowRight/><Skeleton variant="rounded" height={24} width={120} sx={{ fontSize: '1rem',bgcolor: 'rgba(22,29,41,0.9)'}}/>
                                    </div>
                                    <div className='cl-item'>
                                        <MdKeyboardArrowRight/><Skeleton variant="rounded" height={24} width={120} sx={{ fontSize: '1rem',bgcolor: 'rgba(22,29,41,0.9)'}}/>
                                    </div>
                                    <div className='cl-item'>
                                        <MdKeyboardArrowRight/><Skeleton variant="rounded" height={24} width={120} sx={{ fontSize: '1rem',bgcolor: 'rgba(22,29,41,0.9)'}}/>
                                    </div>
                                    <div className='cl-item'>
                                        <MdKeyboardArrowRight/><Skeleton variant="rounded" height={24} width={120} sx={{ fontSize: '1rem',bgcolor: 'rgba(22,29,41,0.9)'}}/>
                                    </div>
                                    <div className='cl-item'>
                                        <MdKeyboardArrowRight/><Skeleton variant="rounded" height={24} width={120} sx={{ fontSize: '1rem',bgcolor: 'rgba(22,29,41,0.9)'}}/>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}

export default CatalogHome;