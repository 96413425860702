import React, { useRef, useState } from 'react';
import '../../../css/Navbar.css';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {RiDashboard3Line, RiLogoutBoxLine} from 'react-icons/ri';
import { logout } from '../../../services/operations/authOperations';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { ACCOUNT_TYPE } from '../../../utils/constants';

function ProfileDropDown() {
    const [open,setOpen] = useState(false);
    const {user} = useSelector((state) => (state.profile));
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userImg = user?.profilePicture;
    const ref=useRef(null);
    useOnClickOutside(ref, () => setOpen(false));
    return (
        <div className='profile-drop-down'>
            <div className='profile-img' onClick={()=>setOpen(true)}>
                <img src={userImg} alt='_profile' />
            </div>
            {
                open &&
                <div className='pdd-menu' ref={ref}>
                    <Link to={user.accountType !== ACCOUNT_TYPE.CONSUMER ? '/dashboard/my-products' : '/dashboard/my-profile'} className='pdd-menu-item' onClick={() => (setOpen(false))}><RiDashboard3Line/> Dashboard</Link>
                    <div className='pdd-menu-item' 
                    onClick={() => {
                        dispatch(logout(navigate));
                        setOpen(false);
                    }}><RiLogoutBoxLine/> Logout</div>
                </div>
            }
        </div>
    );
}

export default ProfileDropDown;