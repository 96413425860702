import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) :null,
    userFullDetails:null,
    profileLoader:false,
}

export const profileSlice = createSlice({
    name:'profile',
    initialState:initialState,
    reducers:{
        setUser:(state,value) => {
            state.user = value.payload;
        },
        setUserFullDetails:(state,value) => {
            state.userFullDetails = value.payload;
        },
        setProfileLoader:(state,value) => {
            state.profileLoader = value.payload;
        }
}});

export const {setUser,setProfileLoader,setUserFullDetails} = profileSlice.actions;
export default profileSlice.reducer;

