import React from 'react';
import '../../../css/Navbar.css';
import { Link, matchPath, useLocation } from 'react-router-dom';
import Logo from '../../../assets/Logo/Logo-Full-Light.png';
import { NavbarLinks } from '../../../data/navbar-links';
import { FaAngleDown } from 'react-icons/fa';
import {HiOutlineBars3BottomLeft} from 'react-icons/hi2';
import { useDispatch, useSelector } from 'react-redux';
import ProfileDropDown from '../auth/ProfileDropDown';
import { setShowSidebar } from '../../../slices/sidebarSlice';

function Navbar() {
    const location = useLocation();
    const dispatch = useDispatch();
    const matchRoute = (route) => {
        return matchPath({path:route},location.pathname)
    }
    const {token} = useSelector((state) => (state.auth));
    const {user} = useSelector((state) => (state.profile));
    const {showSidebar} = useSelector((state) => (state.sidebar));
    const {productCategories} = useSelector((state) => (state.product));
    return (
        <div className='navbar-contaniner'>
            <div className="navbar">
                <div className='main-logo'>
                    {
                        location.pathname.includes('dashboard') && !showSidebar &&
                        <HiOutlineBars3BottomLeft className='sidebar-icon' onClick={() => dispatch(setShowSidebar(true))}/>
                    }
                    {
                        location.pathname.includes('dashboard') && showSidebar &&
                        <HiOutlineBars3BottomLeft className='sidebar-icon'/>
                    }
                    <Link to='/'><img src={Logo} alt="_logo"/></Link>
                </div>
                <nav>
                    {
                        NavbarLinks.map((link,index) => (
                            link?.path === undefined ?
                            (
                                <div className='catalog' key={index}>
                                  Catalog <FaAngleDown/>
                                  <div className='catalog-dropdown'>
                                    <div className='cdd-triangle'></div>
                                    <div className='cdd-nav'>
                                       {
                                        productCategories.length > 0 &&
                                        productCategories.map((category) => (
                                            <Link to={`/catalog/${category.title.split(' ').join('-').toLowerCase()}`} key={category._id} >
                                                {category.title}
                                            </Link>
                                        ))
                                       }
                                    </div>
                                  </div>
                                </div> 
                            ):
                            (
                                <Link to={link?.path} className={matchRoute(link.path) ? 'active-nav nav-item' : 'nav-item'} key={index}>{link.title}</Link> 
                            )
                        ))
                    }
                </nav>
                <div className='user-nav'>
                    {
                        token === null && 
                        <> 
                            <Link to='/signup' className='ls-btn'>Signup</Link>
                            <Link to='/login' className='ls-btn'>Login</Link>
                        </>
                    }
                    {
                        token !== null && user!==null && <ProfileDropDown/>
                    }
                </div>
            </div>
        </div>
    );
}

export default Navbar;