const BASE_URL = process.env.REACT_APP_BASE_URL;

export const endpoints = {
    SENDOTP_API: BASE_URL+'/auth/sendOtp',
    SENDOTP_ADMIN_API: BASE_URL+'/auth/sendOtpAdmin',
    SIGNUP_ADMIN_API: BASE_URL+'/auth/signUpAdmin',
    SIGNUP_API: BASE_URL+'/auth/signUp',
    LOGIN_API: BASE_URL+'/auth/login',
}
export const profileEndpoints ={
    RESET_PASSWORD_TOKEN_API:BASE_URL+'/profile/resetPasswordToken',
    RESET_PASSWORD_API:BASE_URL+'/profile/resetPassword',
    GET_USER_DETAILS:BASE_URL+'/profile/getUserDetails',
    CHANGE_PROFILE_PICTURE: BASE_URL + '/profile/updateDisplayPicture',
    UPDATE_PROFILE: BASE_URL + '/profile/updateProfile',
    CHANGE_PASSWORD: BASE_URL + '/profile/changePassword',
    DELETE_USER: BASE_URL + '/profile/deleteUser'
}
export const productEndpoints = {
    SHOW_ALL_CATEGORIES: BASE_URL+ '/product/showAllCategories',
    SHOW_ALL_PRODUCTS: BASE_URL+ '/product/showAllProducts',
    CREATE_CATEGORY: BASE_URL + '/product/createCategory',
    CREATE_PRODUCT: BASE_URL + '/product/createProduct',
    DELETE_PRODUCT: BASE_URL + '/product/deleteProduct',
    EDIT_PRODUCT: BASE_URL + '/product/editProduct',
    ADD_TO_LIST: BASE_URL + '/product/addToList',
    SUPER_USER_LIST: BASE_URL + '/product/superUserList',
    DELETE_FROM_SU_LIST: BASE_URL + '/product/deleteFromSUList',
    DELETE_ALL_SU_LIST: BASE_URL + '/product/deleteAllSUList',
    CATALOG_LIST: BASE_URL + '/product/catalogList',
    ADD_TO_WISHLIST : BASE_URL + '/product/addToWishlist',
    CONSUMER_WISHLIST : BASE_URL + '/product/consumerWishlist',
    DELETE_FROM_WISHLIST: BASE_URL + '/product/deleteFromWishlist',
    DELETE_ALL_WISHLIST : BASE_URL + '/product/deleteAllWishlist',
    HOME_LIST : BASE_URL + '/product/filteredProducts'
}