import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    showSidebar:false,
}

export const sidebarSlice = createSlice({
    name:'sidebar',
    initialState:initialState,
    reducers:{
        setShowSidebar:(state,value) => {
            state.showSidebar = value.payload;
        }
    }
});

export const {setShowSidebar} = sidebarSlice.actions;
export default sidebarSlice.reducer;

