import React, { useEffect, useState } from 'react';
import '../../../css/Auth.css';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import OTPInput from 'react-otp-input';
import toast from 'react-hot-toast';
import { sendOtpAdmin, signUpAdmin } from '../../../services/operations/authOperations';
import { LiaLongArrowAltLeftSolid } from 'react-icons/lia';
import {GiAnticlockwiseRotation} from 'react-icons/gi';

function VerifyEmailAdmin() {
    const {token} = useSelector((state)=>(state.auth));
    const {signUpData,authLoader} = useSelector((state) => (state.auth));
    const navigate = useNavigate();
    const dispatch =useDispatch();
    const [otp,setOtp] = useState('');
    const submitHandler = (e) => {
        e.preventDefault();
        if(otp.length < 6){
            return toast.error("Enter verification code");
        }
        const {firstName,lastName,email,password,confirmPassword,accountType} = signUpData;
        dispatch(signUpAdmin(firstName,lastName,email,password,confirmPassword,otp,accountType,navigate,token));
    }
    const resendOtpHandler = () => {
        dispatch(sendOtpAdmin(signUpData.email,token,navigate));
    }
    useEffect(()=>{
        if(!signUpData){
            navigate('/dashboard/add-user');
        }
    },[]);

    return (
        <div className='page-container'>
            <div className='dark-block'>
                <div className='verify-page'>
                    <form className='auth-form-container' onSubmit={submitHandler}>
                        <div className='main-title'>
                            Verify email
                        </div>
                        <div className='main-des'>
                            A verification code has been sent to you.Enter the code below
                        </div>
                        <div className='auth-form'>
                            <OTPInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                inputType='tel'
                                renderInput={(props) => <input {...props} />}
                                shouldAutoFocus={true}
                                inputStyle='otp-input'
                                skipDefaultStyles={true}
                                containerStyle={{justifyContent:'space-between'}}
                                placeholder='------'
                            />
                            <button type='submit' className='form-btn' disabled={authLoader ? true : false}>
                                Verify email
                            </button>
                        </div>
                        <div className='below-btn-items'>
                            <Link to='/signup'><LiaLongArrowAltLeftSolid/> Back to signup</Link>
                            <div className='resend-btn' onClick={resendOtpHandler}><GiAnticlockwiseRotation/> Resend it</div>
                        </div>
                    </form>
                </div>
            </div>
            {
                authLoader &&
                <div className='loader-overlay'>
                    <div className="dots"></div>
                </div>
            }
        </div>
    );
}

export default VerifyEmailAdmin;