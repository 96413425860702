import { ACCOUNT_TYPE } from "../utils/constants";
export const sidebarLinks = [
  {
    id: 1,
    name: "All Products",
    path: "/dashboard/my-products",
    type: ACCOUNT_TYPE.SUPERUSER,
    icon: "VscVm",
  },
  {
    id: 2,
    name: "Create Order",
    path: "/dashboard/create-order",
    type: ACCOUNT_TYPE.SUPERUSER,
    icon: "VscGitPullRequestCreate",
  },
  {
    id: 3,
    name: "My Wishlist",
    path: "/dashboard/my-wishlist",
    type: ACCOUNT_TYPE.CONSUMER,
    icon: "VscHeart",
  },
  {
    id: 4,
    name: "My List",
    path: "/dashboard/my-list",
    type: ACCOUNT_TYPE.SUPERUSER,
    icon: "VscChecklist",
  },
  {
    id: 5,
    name: "Add Product",
    path: "/dashboard/add-product",
    type: ACCOUNT_TYPE.SUPERUSER,
    icon: "VscAdd",
  },
  {
    id: 6,
    name: "Add Category",
    path: "/dashboard/add-category",
    type: ACCOUNT_TYPE.ADMIN,
    icon: "VscAdd",
  },
  {
    id: 7,
    name: "Add User",
    path: "/dashboard/add-user",
    type: ACCOUNT_TYPE.ADMIN,
    icon: "VscAdd",
  },
  {
    id: 8,
    name: "My Profile",
    path: "/dashboard/my-profile",
    icon: "VscAccount",
  },
];
