import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Home from './pages/Home';
import Catalog from './pages/Catalog';
import Navbar from './components/core/common/Navbar';
import Login from './pages/Login';
import VerifyEmail from './pages/VerifyEmail';
import ForgotPassword from './pages/ForgotPassword';
import UpdatePassword from './pages/UpdatePassword';
import EditProduct from './components/core/Dashboard/EditProduct';
import Profile from './components/core/Dashboard/Profile';
import AddProduct from './components/core/Dashboard/AddProduct';
import AddUser from './components/core/Dashboard/AddUser';
import AddCategory from './components/core/Dashboard/AddCategory';
import AllProducts from './components/core/Dashboard/AllProducts';
import Settings from './components/core/Dashboard/Settings';
import OpenRoute from './components/core/auth/OpenRoute';
import SURoute from './components/core/auth/SURoute';
import AdminRoute from './components/core/auth/AdminRoute';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { verifyToken } from './services/operations/authOperations';
import ProtectedRoute from './components/core/auth/ProtectedRoute';
import Dashboard from './pages/Dashboard';
import { fetchCategories, fetchListProducts, fetchProducts, fetchWishlistProducts } from './services/operations/productOperations';
import { ACCOUNT_TYPE } from './utils/constants';
import MyList from './components/core/Dashboard/MyList';
import MyWishlist from './components/core/Dashboard/MyWishlist';
import Error from './pages/Error';
import Signup from './pages/Signup';
import VerifyEmailAdmin from './components/core/Dashboard/VeriEmailAdmin';
import About from './pages/About';
import Contact from './pages/Contact';
import CatalogHome from './pages/CatalogHome';
import CreateOrder from './components/core/Dashboard/CreateOrder';

function App() {
  const {token,loggedIn} = useSelector((state) => (state.auth));
  const {user} = useSelector((state) => (state.profile));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    if(token !== null){
      dispatch(verifyToken(token,navigate));
    }
    dispatch(fetchCategories());
  },[]);
  useEffect(() => {
      if(loggedIn && token !== null && user.accountType !== ACCOUNT_TYPE.CONSUMER)
      {
        dispatch(fetchProducts(token));
        dispatch(fetchListProducts(token));
      }
      if(loggedIn && token !== null && user.accountType === ACCOUNT_TYPE.CONSUMER)
      {
        dispatch(fetchWishlistProducts(token));
      }
  },[loggedIn]);
  useEffect(() => {
    window.scrollTo({
      top:0, 
      behavior: "smooth"
  });
    
  }, [pathname]);

  return (
   
      <div className='page-wrapper' >
        <Navbar/>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/catalog" element={<CatalogHome/>}/>
          <Route path="/catalog/:category" element={<Catalog/>}/>
          <Route path="/login" element={<OpenRoute> <Login/> </OpenRoute>}/>
          <Route path="/signup" element={<OpenRoute> <Signup/> </OpenRoute>}/>
          <Route path="/verify-email" element={<OpenRoute><VerifyEmail/></OpenRoute>}/>
          <Route path="/forgot-password" element={<OpenRoute> <ForgotPassword/> </OpenRoute>}/>
          <Route path="/reset-password/:id" element={<OpenRoute> <UpdatePassword/> </OpenRoute>}/>
          <Route element={<ProtectedRoute> <Dashboard/> </ProtectedRoute>}>
            <Route path="/dashboard/edit-product" element={ <SURoute><EditProduct/></SURoute>}/>
            <Route path="/dashboard/my-profile" element={ <Profile/>}/>
            <Route path="/dashboard/my-wishlist" element={ <MyWishlist/>}/>
            <Route path="/dashboard/my-products" element={ <SURoute><AllProducts/></SURoute>}/>
            <Route path="/dashboard/my-list" element={ <SURoute><MyList/></SURoute>}/>
            <Route path="/dashboard/add-product" element={ <SURoute><AddProduct/></SURoute>}/>
            <Route path="/dashboard/create-order" element={ <SURoute><CreateOrder/></SURoute>}/>
            <Route path="/dashboard/add-category" element={ <AdminRoute><AddCategory/></AdminRoute>}/>
            <Route path="/dashboard/add-user" element={ <AdminRoute><AddUser/></AdminRoute>}/>
            <Route path="/dashboard/add-user/verify-email" element={<AdminRoute><VerifyEmailAdmin/></AdminRoute>}/>
            <Route path="/dashboard/settings" element={ <Settings/>}/>
          </Route>
          <Route path="*" element={<Error />}/>
        </Routes>
      </div>
 );
}

export default App;
