import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { FaCheckCircle } from 'react-icons/fa';
import { LiaLongArrowAltLeftSolid } from 'react-icons/lia';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate} from 'react-router-dom';
import { updatePassword } from '../services/operations/authOperations';

const passIns = [
    'one lowercase character',
    'one special character',
    'one uppercase character',
    '8 character minimum',
    'one number'
]

function UpdatePassword() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [formData,setFormData] = useState({
        password:"",confirmPassword:""
    });
    const [showPassword,setShowPassword] =useState();
    const [resetDone,setResetDone] = useState(false);
    const [showConfirmPassword,setShowConfirmPassword] =useState();
    const {authLoader} = useSelector((state)=>(state.auth));

    const changeHandler = (e) => {
        setFormData((prev) => ({
            ...prev , [e.target.name] : e.target.value
        }))
    };


    const submitHandler = (e) => {
        e.preventDefault();
        if(!formData.password){
            return toast.error('Password is Required');
        }
        if(formData.password.length < 6 || formData.password.length > 10){
            return toast.error('Password Length should 6-10 chars');
        }
        if(!formData.confirmPassword){
            return toast.error('Confirm Password is Required');
        }
        if(formData.password !== formData.confirmPassword)
        {
            return toast.error('Passwords do not match');
        }
        const token = location.pathname.split('/').at(-1);
        dispatch(updatePassword(token,formData.password,formData.confirmPassword,setResetDone));
    }
    return (
        <div className='page-container'>
            <div className='dark-block'>
                <div className="verify-page">
                    <form className='auth-form-container' onSubmit={submitHandler}>
                        <div className='main-title'>
                            {resetDone ? 'Reset complete!' : 'Choose new password'}
                        </div>
                        <div className='main-des'>
                            {
                                resetDone ? 'All done! We have sent an email to m***********@gmail.com to confirm'
                                : 'Almost done. Enter your new password and youre all set.'
                            }
                        </div>
                        <div className='auth-form'>
                            {
                                !resetDone &&
                                <div className='form-item'>
                                    <label htmlFor='password'>New password <sup>*</sup></label>
                                    <input
                                        type={showPassword ? 'text' : 'password'}
                                        name='password'
                                        placeholder='******'
                                        value={formData.password}
                                        onChange={changeHandler}
                                    />
                                    <div className='eye-btn'onClick={()=>(setShowPassword((prev)=>(!prev)))}>
                                        {showPassword ? <AiOutlineEyeInvisible/> : <AiOutlineEye/>}
                                    </div>
                                </div>
                            }
                            {
                                !resetDone &&
                                <div className='form-item'>
                                    <label htmlFor='confirmPassword'>Confirm new password <sup>*</sup></label>
                                    <input
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        name='confirmPassword'
                                        placeholder='******'
                                        value={formData.confirmPassword}
                                        onChange={changeHandler}
                                    />
                                    <div className='eye-btn' onClick={()=>(setShowConfirmPassword((prev)=>(!prev)))}>
                                        {showConfirmPassword ? <AiOutlineEyeInvisible/> : <AiOutlineEye/>}
                                    </div>
                                </div>
                            }
                            {
                                !resetDone &&
                                <div className='password-ins'>
                                    {
                                        passIns.map((txt,index) => (
                                            <div className='pass-ins-item' key={index}>
                                                <FaCheckCircle/> {txt}
                                            </div>
                                        ))
                                    }
                                </div>
                            }
                            {
                                resetDone ?
                                <button type='button' className='form-btn' onClick={() => (navigate('/login'))}>
                                    Return to login
                                </button>
                                :
                                <button type='submit' className='form-btn' disabled={authLoader ? true : false}>
                                    Reset Password
                                </button>
                            }
                        </div>
                        <div className='below-btn-items'>
                            <Link to='/login'><LiaLongArrowAltLeftSolid/> Back to login</Link>
                        </div>
                    </form>
                </div>
            </div>
            {
                authLoader &&
                <div className='loader-overlay'>
                    <div className="dots"></div>
                </div>
            }
        </div>
    );
}

export default UpdatePassword;