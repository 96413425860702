import React, { useState } from 'react';
import {MdDeleteOutline} from 'react-icons/md';
import ModalAlert from '../../common/ModalAlert';
import { deleteUserProfile } from '../../../../services/operations/profileOperations';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function DeleteAccount() {
    const [modal, setModal] = useState(false);
    const {token} = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    return (
        <>
            <div className='delete-profile-tile'>
                <div className='dp-container'>
                    <div className='dp-icon'>
                        <MdDeleteOutline/>
                    </div>
                    <div className='dp-txt-items'>
                        <div className='tile-name'>
                            Delete Account
                        </div>
                        <p>Would you like to delete account?</p>
                        <p>This account contains Paid Courses. Deleting your account will remove all the contain associated with it.</p>
                        <button type='button' className='dp-btn' onClick={() => (setModal(true))}>
                            I want to delete my account.
                        </button>
                    </div>
                </div>
            </div>  
            {
                modal && 
                <ModalAlert 
                    txt1 = 'Are you sure ?'
                    txt2 = 'Your account will be deleted permanently'
                    btn1txt = 'Confirm'
                    btn2txt = 'Cancel'
                    handler1 = {() => (dispatch(deleteUserProfile(token,navigate)))}
                    handler2 = {() => (setModal(false))}
                />
            }
        </>
        
        
    );
}

export default DeleteAccount;