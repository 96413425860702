import React, { useState } from 'react';
import '../css/Auth.css';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { LiaLongArrowAltLeftSolid } from 'react-icons/lia';
import toast from 'react-hot-toast';
import { resetPassword } from '../services/operations/authOperations';

function ForgotPassword() {

    const {authLoader} = useSelector((state) => (state.auth));
    const [emailSent,setEmailSent] = useState(false);
    const [email,setEmail] =useState('');
    const dispatch = useDispatch();

    const submitHandler = (event) => {
        event.preventDefault();
        if(!email.trim()){
            return toast.error('Email is Required');
        }
        if(!/\S+@\S+\.\S+/.test(email)){
            return toast.error('Enter a valid Email');
        }
        dispatch(resetPassword(email,setEmailSent));
    }

    return (
        <div className='page-container'>
            <div className='dark-block'>
                <div className='verify-page'>
                    <form className='auth-form-container' onSubmit={submitHandler}>
                        <div className='main-title'>
                            {
                                emailSent ? 'Check email' : 'Reset your password'
                            }
                        </div>
                        <div className='main-des'>
                            {
                                emailSent ? 
                                `We have sent the reset email to ${email}`:
                                "Have no fear. We'll email you instructions to reset your password. If you dont have access to your email we can try account recovery" 
                                
                            }
                        </div>
                        <div className='auth-form'>
                            {
                                !emailSent &&
                                <div className='form-item'>
                                    <label htmlFor='email'>Email Address <sup>*</sup></label>
                                    <input 
                                        type='text' 
                                        name='email' 
                                        placeholder='Enter email address'
                                        value={email}
                                        onChange={(e)=>(setEmail(e.target.value))}
                                    />
                                </div>
                            }
                            <button type='submit' className='form-btn' disabled={authLoader ? true : false}>
                                {emailSent ? 'Resend Email' : 'Reset Password'}
                            </button> 
                        </div>
                        <div className='below-btn-items'>
                            <Link to='/login'><LiaLongArrowAltLeftSolid/> Back to login</Link>
                        </div>
                    </form>
                </div>
            </div>
            {
                authLoader &&
                <div className='loader-overlay'>
                    <div className="dots"></div>
                </div>
            }
        </div>
    );
}

export default ForgotPassword;