import { Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BsImageFill } from 'react-icons/bs';
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import { useDispatch, useSelector } from 'react-redux';
import { FilePathCon } from '../constant';
import { Link, useLocation } from 'react-router-dom';
import { addToWishlist, deleteWishlistProduct, fetchCatalogProducts } from '../services/operations/productOperations';
import { AiOutlineSearch } from 'react-icons/ai';
import { ACCOUNT_TYPE } from '../utils/constants';
import Footer from '../components/core/common/Footer';

function Catalog() {
    const createArray =(N) => {
        let newArr = [];
        for (let i = 1; i <= N; i++) {
            newArr.push(i);
        }
        return newArr;
    }
    const skeletonCount = createArray(12);
    const dispatch = useDispatch();
    const location = useLocation();
    const [catalogList,setCatalogList] = useState([]);
    const [productsToShow,setProductsToShow] = useState([]);
    const [catalogLoader,setCatalogLoader] =useState(false);
    const {productLoader,productCategories,categoryLoader,myList} = useSelector((state)=>(state.product));
    const {token} = useSelector((state) => (state.auth));
    const {user} = useSelector((state) => (state.profile));
    const [noOfItemsToShow,setNoOfItemsToShow] = useState(12);
    const [imgLoaded,setImgLoaded] = useState([]);
    const [modal,setModal] = useState(false);
    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const [searchStr,setSearchStr] = useState('');
    const handleSearchStr = (event) => {
        setSearchStr(event.target.value);
    }
    const catName = location.pathname.split('/').at(-1).split('-').join(' ');
    const findCategory = productCategories.find((category) => (category.title.toLowerCase() === catName));
    useEffect(() => {
        setCatalogList([]);
        if (findCategory){
            dispatch(fetchCatalogProducts(findCategory._id,setCatalogList,setCatalogLoader));
        }
      },[location,productCategories]);
      useEffect(()=>{
        if(searchStr !== '') {
            setProductsToShow(catalogList.filter((product) => (((searchStr.toLowerCase().trim().split(' ').every((str) => (product.title.toLowerCase().trim().includes(str) ))) || (searchStr.toLowerCase().trim().split(' ').every((str) => (product.description.toLowerCase().trim().includes(str) )))))).toSorted((a, b) => b.updatedAt.localeCompare(a.updatedAt)).toSorted((a) => {if (a.flag === "none"){ return 1;}else {return -1;}}).toSorted((a, b) => {if(a.flag !== 'none' && b.flag !== 'none' ){ return b.updatedAt.localeCompare(a.updatedAt)}else{return 0}}))
        }
        else
        {
            setProductsToShow(catalogList.toSorted((a, b) => b.updatedAt.localeCompare(a.updatedAt)).toSorted((a) => {if (a.flag === "none"){ return 1;}else {return -1;}}).toSorted((a, b) => {if(a.flag !== 'none' && b.flag !== 'none' ){ return b.updatedAt.localeCompare(a.updatedAt)}else{return 0}}))
        }
        
    },[catalogList,searchStr])
    return (
        categoryLoader ?
        <div className="page-container">
            <div className="dark-block">
                <div className='loader-overlay'>
                    <div className="dots"></div>
                </div>
            </div>
        </div>
        :
        <>{
            findCategory ?
            <div className='page-container' style={{position:"relative"}}>
                <div className='dark-block'>
                    { 
                    catalogLoader ?
                    <div className='products-container' style={{maxWidth:'1200px',margin:'auto'}}>
                        {   
                            skeletonCount.map(
                                (count) => (
                                    <div className='product-tile' key={count}>
                                        <div className="product-image-container">
                                        <Skeleton
                                            sx={{ bgcolor: 'rgba(22,29,41,0.9)' }}
                                            variant="rounded"
                                            animation="wave"
                                            width={260}
                                            height={220}
                                        />
                                        <BsImageFill style={{color:'#838894',position:'absolute',fontSize:'1.5rem'}}/>
                                        
                                        </div>
                                        <div className='product-title'><Skeleton variant="rounded" height={20} sx={{ fontSize: '1rem',bgcolor: 'rgba(0,8,20,0.9)'}}/></div>
                                        <div className='price-details'>
                                            <div className='selling-price'>
                                            <Skeleton variant="rounded" height={20} width={35} sx={{ fontSize: '1rem',bgcolor: 'rgba(0,8,20,0.9)'}}/>
                                            <Skeleton variant="rounded" height={15} width={80} sx={{ fontSize: '1rem',bgcolor: 'rgba(0,8,20,0.9)'}}/>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )
                        }
                    </div> :
                    <>
                        <div className='search-filter-container' style={{maxWidth:'1200px',margin:'auto'}}>
                            <div className='search-product'>
                                <input type='text' placeholder='Search Product' value={searchStr} onChange={handleSearchStr} spellCheck="false" />
                                <AiOutlineSearch/>
                            </div>
                        </div>
                        <div className='products-container' style={{maxWidth:'1200px',margin:'auto'}}>
                            {   
                                productsToShow.length > 0 ?
                                productsToShow.slice(0,noOfItemsToShow).map(
                                    (product) => (
                                        <div className='product-tile' key={product._id}>
                                            <div className="product-image-container">
                                                {
                                                    !imgLoaded.includes(product._id) && 
                                                    <>
                                                        <Skeleton
                                                        sx={{ bgcolor: 'rgba(22,29,41,0.9)' }}
                                                        variant="rounded"
                                                        animation="wave"
                                                        width={260}
                                                        height={220}
                                                        />
                                                        <BsImageFill style={{color:'#838894',position:'absolute',fontSize:'1.5rem'}}/>
                                                    </>
                                                }
                                                <img src={FilePathCon + product.thumbnail} style={!imgLoaded.includes(product._id) ? {display:'none'} : {display:'block'}} alt='img' className='product-image' onLoad={()=>(setImgLoaded((prev)=>[...prev,product._id]))} />
                                            </div>
                                            <div className='product-title'>{product.description}</div>
                                            <div className='price-details'>
                                                <div className='selling-price'>
                                                    &#8377; {product.sellingPrice}
                                                    {
                                                        parseInt(product.sellingPrice) < parseInt(product.mrp) ?
                                                        <div className='mrp-container'>
                                                            <span>MRP: <del>&#8377; {product.mrp}</del></span>
                                                            &nbsp;
                                                            <span className='off-on-mrp'>
                                                                {Math.round((parseInt(product.mrp)-parseInt(product.sellingPrice))*100/parseInt(product.mrp))}
                                                                &#37; off
                                                            </span>
                                                        </div> 
                                                        : 
                                                        ''
                                                    }
                                                </div>
                                                {
                                                    product.flag !== 'none' &&
                                                    <div className='product-flag'>{capitalizeFirstLetter(product.flag)}</div>
                                                }
                                                {
                                                    token !== null && user!==null && user.accountType === ACCOUNT_TYPE.CONSUMER &&
                                                    <div className='edit-delete-product'>
                                                        {
                                                            !myList.some((prod) => (prod._id === product._id)) ?
                                                            <button className='edit-product-btn' style={{fontSize:'1.1rem'}} onClick={() => {dispatch(addToWishlist(token,product._id))}}><IoMdHeartEmpty/></button>
                                                            : 
                                                            <button className='edit-product-btn' style={{fontSize:'1.1rem'}} onClick={() => {dispatch(deleteWishlistProduct(token,product._id,setModal))}}><IoMdHeart/></button>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                ) : 
                                <div className='not-found'>No Product Found</div>
                            }

                        </div>
                    </>
                    }
                    {
                        (productsToShow.length > noOfItemsToShow) &&
                        <div className='show-more-container'>
                            <button type='button' className='form-btn show-more' onClick={()=>(setNoOfItemsToShow(noOfItemsToShow + 12))}>Show More</button>
                        </div>
                    }
                </div>
                {
                    productLoader &&
                    <div className='loader-overlay'>
                        <div className="dots"></div>
                    </div>
                }
            </div>
            :
            <div className='page-container'>
                <div className='dark-block' style={{color:'#DBDDEA',padding:'170px 15px',textAlign:'center',fontSize:'25px',display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column',gap:'15px'}}>
                    <span style={{fontSize:'80px',fontWeight:'700',lineHeight:'80px'}} className='gradient-txt'>404</span>
                    <span style={{fontStyle:'italic'}}>PAGE NOT FOUND</span>
                    <span style={{fontSize:'17px',color:'#838894'}}>Sorry, the page you're looking for doesn't exist.</span>
                    <Link to='/' className='primary-btn' style={{marginTop:'10px',padding:'10px 20px'}}>Return Home</Link>
                </div>
            </div>
        }
        <Footer/>
        </>
    );
}

export default Catalog;