import React from 'react';
import Footer from '../components/core/common/Footer';
import { Link } from 'react-router-dom';
import { IoIosMail, IoLogoWhatsapp } from 'react-icons/io';
import { IoLocation } from "react-icons/io5";
import '../css/Static.css';

function Contact() {
    return (
        <>
            <div className='page-container'>
                <div className="static-title-container">
                    <div className="static-title">
                        <div className='gradient-txt'>Contact Us</div>
                        <div className='small-border'></div>
                    </div>
                </div>
                <div className='dark-block'>
                    <div className='contact-us-container'>
                        <div className='contact-details'>
                            <div className='contact-details-item'>
                                <div className='cd-item-title'><IoIosMail/> Email Us</div>
                                <div className="cd-item-des">
                                    For any query mail us to
                                    <Link to='mailto:support@rkeservices.in'> support@rkeservices.in</Link>
                                </div> 
                            </div>
                            <div className='contact-details-item'>
                                <div className='cd-item-title'><IoLogoWhatsapp/> Chat with Us</div>
                                <div className='cd-item-des'>
                                    <Link to='https://wa.me/918950722210' target='blank'>Click Here </Link>to chat with us on Whatsapp
                                </div>
                            </div>
                            <div className='contact-details-item'>
                                <div className='cd-item-title'><IoLocation/> Locate Us</div>
                                <div className='cd-item-des'>
                                    R.K Mobile and Ecafe, Barwala Road, Roop Nagar Colony, Hansi, Hisar, Haryana 125033
                                </div>
                            </div>
                        </div>
                        <div className='map-container'>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d217.89154110595481!2d75.95618625859235!3d29.097866992561936!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39123904eba02417%3A0xf8a81e1dd1ffe540!2sR.K%20PROVISIONAL%20STORE!5e0!3m2!1sen!2sin!4v1721197621798!5m2!1sen!2sin"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="map"></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}

export default Contact;