import React from 'react';
import ChangeProfileImg from './ChangeProfileImg';
import UpdateProfileInfo from './UpdateProfileInfo';
import UpdatePassword from './UpdatePassword';
import DeleteAccount from './DeleteAccount';
import { ACCOUNT_TYPE } from '../../../../utils/constants';
import { useSelector } from 'react-redux';


function Settings() {
    const {user} = useSelector((state) => (state.profile))
    
    return (
        <div className='page-container'>
            <div className='dark-block'>
                <div className='profile-tiles-container'>
                    <div className='dashboard-page-title'>
                        Edit Profile
                    </div>
                    <ChangeProfileImg/>
                    <UpdateProfileInfo/>
                    <UpdatePassword/>
                    {(user.accountType !== ACCOUNT_TYPE.ADMIN) && <DeleteAccount/>}
                </div>
            </div>
        </div>
    );
}

export default Settings;