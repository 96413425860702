import React, { useEffect, useState } from 'react';
import '../css/Auth.css';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { setSignUpData } from '../slices/authSlice';
import toast from 'react-hot-toast';
import { login } from '../services/operations/authOperations';
import { StaticFilePathCon } from '../constant';

function Login() {
    const {authLoader} = useSelector((state)=>(state.auth));
    const [showPassword,setShowPassword] = useState(false);
    const navigate = useNavigate();
    const [formData, setFormData] =useState({
        email:'',password:''
    });
    const changeHandler = (event) => {
        setFormData((prev) => (
            {
                ...prev,[event.target.name]:event.target.value
            }
        ));
    }
    const submitHandler = (event) => {
        event.preventDefault();
        if(!formData.email.trim()){
            return toast.error('Email is Required');
        }
        if(!/\S+@\S+\.\S+/.test(formData.email)){
            return toast.error('Enter a valid Email');
        }
        if(!formData.password){
            return toast.error('Password is Required');
        }
        dispatch(login(formData.email,formData.password,navigate));
    }
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(setSignUpData(null));
    },[]);
    return (
        <div className='page-container'>
            <div className='dark-block'>
                <div className='auth-page'>
                    <div className='auth-form-container'>
                        <div className='main-title'>
                            Welcome Back
                        </div>
                        <div className='main-des'>
                            Enter your credentials to securely access your product dashboard. 
                            <span className='highlight-des'> Products that make you smile.</span>
                        </div>
                        <form className='auth-form' onSubmit={submitHandler}>
                            <div className='form-item'>
                                <label htmlFor='email'>Email Address <sup>*</sup></label>
                                <input 
                                    type='text' 
                                    name='email' 
                                    placeholder='Enter email address'
                                    value={formData.email}
                                    onChange={changeHandler}
                                />
                            </div>
                            <div className='form-item'>
                                <label htmlFor='password'>Password <sup>*</sup></label>
                                <input 
                                    type={showPassword ? 'text' : 'password'} 
                                    name='password' 
                                    placeholder='Enter Password'
                                    value={formData.password}
                                    onChange={changeHandler}   
                                />
                                <div className='eye-btn'onClick={()=>(setShowPassword((prev)=>(!prev)))}> 
                                    {showPassword ? <AiOutlineEyeInvisible/> : <AiOutlineEye/>} 
                                </div>
                                <Link to={'/forgot-password'} className='forgot-pass'>Forgot password</Link>
                            </div>
                            <button type='submit' className='form-btn'>
                                Sign in
                            </button>
                        </form>
                    </div>
                    <div className='ap-frames'>
                        <img src={StaticFilePathCon + '/frame.png'} alt='_frame' className='frame-img'/>
                        <img src={StaticFilePathCon + '/login.jpg'} alt='_picture' className='picture-img'/>
                    </div>
                </div>
            </div>
            {
                authLoader &&
                <div className='loader-overlay'>
                    <div className="dots"></div>
                </div>
            }
        </div>
    );
}

export default Login;