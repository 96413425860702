import { profileEndpoints } from "../apis";
import { setUser, setUserFullDetails } from "../../slices/profileSlice";
import { setLoggedIn, setToken } from "../../slices/authSlice";
import toast from "react-hot-toast";
import {apiConnector} from "../apiConnector";
import { setAllProducts, setMyList, setProductToEdit } from "../../slices/productSlice";

const {CHANGE_PROFILE_PICTURE,UPDATE_PROFILE,CHANGE_PASSWORD,DELETE_USER} = profileEndpoints;

export function changeProfilePicture(token,formData){
    return async (dispatch) => {
        await apiConnector('PUT',CHANGE_PROFILE_PICTURE,formData,null,{Authorization: `Bearer ${token}`}).then((response) =>{
            if(!response.data.success){
                throw new Error(response.data.message);
            }
            toast.success(response.data.message);
            const userImage = response.data?.updatedUser?.profilePicture
                ? response.data.updatedUser.profilePicture
                : `https://api.dicebear.com/5.x/initials/svg?seed=${response.data.updatedUser.firstName} ${response.data.updatedUser.lastName}`
            const shortUser = { accountType:response.data.updatedUser.accountType, profilePicture: userImage }
            dispatch(setUser(shortUser));
            dispatch(setUserFullDetails({...response.data.updatedUser , profilePicture: userImage}))
            localStorage.setItem("user", JSON.stringify(shortUser) );
        })
        .catch((error) =>{
            if(error.response){
                toast.error(error.response.data.message);
            }
            else if(error.request){
                toast.error('No response Recieved');
            }
            else
            {
                toast.error('Server Error');
            }
        }
        )
    }
}
export function updateProfileDetails(token,formData){
    return async (dispatch) => {
        await apiConnector('PUT',UPDATE_PROFILE,formData,null,{Authorization: `Bearer ${token}`}).then((response) =>{
            if(!response.data.success){
                throw new Error(response.data.message);
            }
            toast.success(response.data.message)
            const userImage = response.data?.updatedUser?.profilePicture
                ? response.data.updatedUser.profilePicture
                : `https://api.dicebear.com/5.x/initials/svg?seed=${response.data.updatedUser.firstName} ${response.data.updatedUser.lastName}`
            const shortUser = { accountType:response.data.updatedUser.accountType, profilePicture: userImage }
            dispatch(setUser(shortUser));
            dispatch(setUserFullDetails({...response.data.updatedUser , profilePicture: userImage}))
            localStorage.setItem("user", JSON.stringify(shortUser) );
        })
        .catch((error) =>{
            if(error.response){
                toast.error(error.response.data.message);
            }
            else if(error.request){
                toast.error('No response Recieved');
            }
            else
            {
                toast.error('Server Error');
            }
        }
        )
    }
}
export function changeLoginPassword(token,formData){
    return async (dispatch) => {
        await apiConnector('PUT',CHANGE_PASSWORD,formData,null,{Authorization: `Bearer ${token}`}).then((response) =>{
            if(!response.data.success){
                throw new Error(response.data.message);
            }
            toast.success(response.data.message)
        })
        .catch((error) =>{
            if(error.response){
                toast.error(error.response.data.message);
            }
            else if(error.request){
                toast.error('No response Recieved');
            }
            else
            {
                toast.error('Server Error');
            }
        }
        )
    }
}
export function deleteUserProfile(token,navigate){
    return async (dispatch) => {
        await apiConnector('DELETE',DELETE_USER,null,null,{Authorization: `Bearer ${token}`}).then((response) =>{
            if(!response.data.success){
                throw new Error(response.data.message);
            }
            toast.success(response.data.message);
            dispatch(setToken(null))
            dispatch(setUser(null));
            dispatch(setUserFullDetails(null));
            dispatch(setProductToEdit(null));
            dispatch(setAllProducts([]));
            dispatch(setMyList([]));
            dispatch(setLoggedIn(false));
            localStorage.clear();
            navigate("/");
        })
        .catch((error) =>{
            if(error.response){
                toast.error(error.response.data.message);
            }
            else if(error.request){
                toast.error('No response Recieved');
            }
            else
            {
                toast.error('Server Error');
            }
        }
        )
    }
}
