import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { changeLoginPassword } from '../../../../services/operations/profileOperations';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

function UpdatePassword() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {token} = useSelector(state => state.auth);
    const [showPassword,setShowPassword] = useState(false);
    const [showNewPassword,setShowNewPassword] = useState(false);
    const {register,formState : {errors}, handleSubmit} = useForm({mode:"onTouched"});
    const [loading , setLoading] = useState(false);
    const updatePasswordHandler = (data) => {
        setLoading(true);
        dispatch(changeLoginPassword(token,data)).then(
            ()=>setLoading(false)
        )
        
    }

    return (
        <>
            <div className='profile-tile'>
                <div className='profile-tile-header'>
                    <div className='tile-name'>
                        Password
                    </div>
                </div>
                <form className='profile-form'>
                    <div className='pf-items-container'>
                        <div className='profile-form-item'>
                            <label htmlFor='firstName'>Current Password</label>
                            <input 
                                type={showPassword ? 'text' : 'password'}
                                name='password' 
                                placeholder='Enter Current Password'
                                {...register("password",{required:true})}
                            />
                            <button type='button' className='profile-eye-btn' onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ? <AiOutlineEyeInvisible/> : <AiOutlineEye/> }
                            </button>
                            {errors.password && <span>Please Enter Current Password</span>}
                        </div>
                        <div className='profile-form-item'>
                            <label htmlFor='lastName'>New Password</label>
                            <input 
                                type={showNewPassword ? 'text' : 'password'}
                                name='newPassword'
                                placeholder='Enter New Password'
                                {...register("newPassword",{
                                    required:{
                                        value:true,
                                        message:'Please Enter New Password'
                                    },
                                    pattern:{
                                        value: /[A-Z]{1,}[a-z]{1,}[0-9]{1,}[!@#$%^&*()+=~?]{1,}/g,
                                        message: 'Aleast 1 uppercase, 1 lowercase, 1 number and 1 special character'
                                    },
                                    minLength:{
                                        value:8,
                                        message: "Password should be of minimum 8 characters"
                                    },
                                    maxLength:{
                                        value:12,
                                        message: "Password should be of maximum 12 characters"
                                    }
                                })}
                            />
                            <button type='button' className='profile-eye-btn' onClick={() => setShowNewPassword(!showNewPassword)}>
                                {showNewPassword ? <AiOutlineEyeInvisible/> : <AiOutlineEye/> }
                            </button>
                            {errors.newPassword && <span>{errors.newPassword.message}</span>}
                        </div>
                    </div>
                </form>
            </div>
            <div className='cpd-btns'>
                <button type='button' className='cpp-sec-btn' onClick={() => (navigate('/dashboard/my-profile'))}>
                    cancel
                </button>
                <button type='submit' className='cpp-prime-btn' onClick={handleSubmit(updatePasswordHandler)}>
                    {loading ? <div className="spinner"></div> : 'Save'}
                </button>
            </div>
        </>
    );
}

export default UpdatePassword;