import toast from "react-hot-toast";
import { setAuthLoader, setLoggedIn, setToken } from "../../slices/authSlice"
import { apiConnector } from "../apiConnector";
import { endpoints, profileEndpoints } from "../apis";
import { setProfileLoader, setUser, setUserFullDetails } from "../../slices/profileSlice";
import { setAllProducts, setMyList, setProductToEdit } from "../../slices/productSlice";
import { ACCOUNT_TYPE } from "../../utils/constants";

const {SENDOTP_API, SENDOTP_ADMIN_API ,SIGNUP_ADMIN_API, SIGNUP_API ,LOGIN_API } = endpoints;
const {RESET_PASSWORD_TOKEN_API,RESET_PASSWORD_API,GET_USER_DETAILS} = profileEndpoints;


export function verifyToken(token,navigate){
    return async (dispatch) => {
        dispatch(setProfileLoader(true));
        await apiConnector('GET',GET_USER_DETAILS,null,null,{Authorization: `Bearer ${token}`}).then((response) =>{
            if(!response.data.success){
                throw new Error(response.data.message);
            }
            const userImage = response.data?.userDetails?.profilePicture
                ? response.data.userDetails.profilePicture
                : `https://api.dicebear.com/5.x/initials/svg?seed=${response.data.userDetails.firstName} ${response.data.userDetails.lastName}`
            const shortUser = { accountType:response.data.userDetails.accountType, profilePicture: userImage }
            dispatch(setUser(shortUser));
            dispatch(setUserFullDetails({...response.data.userDetails , profilePicture: userImage}))
            localStorage.setItem("user", JSON.stringify(shortUser) );
            dispatch(setLoggedIn(true));
        })
        .catch((error) =>{
            console.log(error);
            localStorage.clear();
            dispatch(setUser(null));
            dispatch(setUserFullDetails(null));
            dispatch(setToken(null));
            dispatch(setProductToEdit(null));
            dispatch(setAllProducts([]));
            dispatch(setMyList([]));
            dispatch(setLoggedIn(false));
            navigate('/');
        }
        )
        dispatch(setProfileLoader(false));
    }
}
export function login(email,password,navigate){
    return async (dispatch) => {
        dispatch(setAuthLoader(true));
        await apiConnector('POST',LOGIN_API,{email,password}).then((response) =>{
            if(!response.data.success){
                throw new Error(response.data.message);
            }
            toast.success(response.data.message);
            dispatch(setToken(response.data.token));
            const userImage = response.data?.existingUser?.profilePicture
                ? response.data.existingUser.profilePicture
                : `https://api.dicebear.com/5.x/initials/svg?seed=${response.data.existingUser.firstName} ${response.data.existingUser.lastName}`
            const shortUser = { accountType:response.data.existingUser.accountType, profilePicture: userImage }
            dispatch(setUser(shortUser));
            dispatch(setUserFullDetails({...response.data.existingUser , profilePicture: userImage}))
            localStorage.setItem("token", JSON.stringify(response.data.token));
            localStorage.setItem("user", JSON.stringify(shortUser) );
            dispatch(setLoggedIn(true));
            if(shortUser.accountType !== ACCOUNT_TYPE.CONSUMER)
            {   
                navigate('/dashboard/my-products');
            }
            else
            {
                navigate('/');
            }
        })
        .catch((error) =>{
            if(error.response){
                toast.error(error.response.data.message);
            }
            else if(error.request){
                toast.error('No response Recieved');
            }
            else
            {
                toast.error('Server Error');
            }
        }
        )
        dispatch(setAuthLoader(false));
    }
}
export function sendOtp(email,navigate){
    return async (dispatch) => {
        dispatch(setAuthLoader(true));
        await apiConnector('POST',SENDOTP_API,{email}).then((response) =>{
            if(!response.data.success){
                throw new Error(response.data.message);
            }
            toast.success(response.data.message);
            navigate('/verify-email');
        })
        .catch((error) =>{
            if(error.response){
                toast.error(error.response.data.message);
            }
            else if(error.request){
                toast.error('No response Recieved');
            }
            else
            {
                toast.error('Server Error');
            }
        }
        )
        dispatch(setAuthLoader(false));
    }
}
export function sendOtpAdmin(email,token,navigate){
    return async (dispatch) => {
        dispatch(setAuthLoader(true));
        await apiConnector('POST',SENDOTP_ADMIN_API,{email},null,{Authorization: `Bearer ${token}`}).then((response) =>{
            if(!response.data.success){
                throw new Error(response.data.message);
            }
            toast.success(response.data.message);
            navigate('/dashboard/add-user/verify-email');
        })
        .catch((error) =>{
            if(error.response){
                toast.error(error.response.data.message);
            }
            else if(error.request){
                toast.error('No response Recieved');
            }
            else
            {
                toast.error('Server Error');
            }
        }
        )
        dispatch(setAuthLoader(false));
    }
}
export function signUp(firstName,lastName,email,password,confirmPassword,otp,navigate){
    return async (dispatch) => {
        dispatch(setAuthLoader(true));
        await apiConnector('POST',SIGNUP_API,{firstName,lastName,email,password,confirmPassword,otp})
        .then((response) =>{
            if(!response.data.success){
                throw new Error(response.data.message);
            }
            toast.success(response.data.message);
            navigate('/');
        })
        .catch((error) =>{
            if(error.response){
                toast.error(error.response.data.message);
            }
            else if(error.request){
                toast.error('No response Recieved');
            }
            else
            {
                toast.error('Server Error');
            }
        }
        )
        dispatch(setAuthLoader(false));
    }
}
export function signUpAdmin(firstName,lastName,email,password,confirmPassword,otp,accountType,navigate,token){
    return async (dispatch) => {
        dispatch(setAuthLoader(true));
        await apiConnector('POST',SIGNUP_ADMIN_API,{firstName,lastName,email,password,confirmPassword,otp,accountType},null,{Authorization: `Bearer ${token}`})
        .then((response) =>{
            if(!response.data.success){
                throw new Error(response.data.message);
            }
            toast.success(response.data.message);
            navigate('/dashboard/add-user');
        })
        .catch((error) =>{
            if(error.response){
                toast.error(error.response.data.message);
            }
            else if(error.request){
                toast.error('No response Recieved');
            }
            else
            {
                toast.error('Server Error');
            }
        }
        )
        dispatch(setAuthLoader(false));
    }
}
export function resetPassword(email,setEmailSent){
    return async (dispatch) => {
        dispatch(setAuthLoader(true));
        await apiConnector('POST',RESET_PASSWORD_TOKEN_API,{email}).then((response) =>{
            if(!response.data.success){
                throw new Error(response.data.message);
            }
            toast.success(response.data.message);
            setEmailSent(true);
        })
        .catch((error) =>{
            if(error.response){
                toast.error(error.response.data.message);
            }
            else if(error.request){
                toast.error('No response Recieved');
            }
            else
            {
                toast.error('Server Error');
            }
        }
        )
        dispatch(setAuthLoader(false));
    }
}
export function updatePassword(token,password,confirmPassword,setResetDone){
    return async (dispatch) => {
        dispatch(setAuthLoader(true));
        await apiConnector('POST',RESET_PASSWORD_API,{token,password,confirmPassword}).then((response) =>{
            if(!response.data.success){
                throw new Error(response.data.message);
            }
            toast.success(response.data.message);
            setResetDone(true);
        })
        .catch((error) =>{
            if(error.response){
                toast.error(error.response.data.message);
            }
            else if(error.request){
                toast.error('No response Recieved');
            }
            else
            {
                toast.error('Server Error');
            }
        }
        )
        dispatch(setAuthLoader(false));
    }
}
export function logout(navigate){
    return (dispatch) => {
        dispatch(setToken(null));
        dispatch(setUser(null));
        dispatch(setUserFullDetails(null));
        dispatch(setProductToEdit(null));
        dispatch(setAllProducts([]));
        dispatch(setMyList([]));
        dispatch(setLoggedIn(false));
        localStorage.clear();
        toast.success('Logged Out Successfully');
        navigate('/');
    }
}