import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/core/common/Footer';

function Error() {
    return (
        <>
            <div className='page-container'>
                <div className='dark-block' style={{color:'#DBDDEA',padding:'170px 15px',textAlign:'center',fontSize:'25px',display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column',gap:'15px'}}>
                    <span style={{fontSize:'80px',fontWeight:'700',lineHeight:'80px'}} className='gradient-txt'>404</span>
                    <span style={{fontStyle:'italic'}}>PAGE NOT FOUND</span>
                    <span style={{fontSize:'17px',color:'#838894'}}>Sorry, the page you're looking for doesn't exist.</span>
                    <Link to='/' className='primary-btn' style={{marginTop:'10px',padding:'10px 20px'}}>Return Home</Link>
                </div>
            </div>
            <Footer/>
        </>
    );
}

export default Error;