import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { createCategory} from '../../../services/operations/productOperations';
import { useDispatch, useSelector } from 'react-redux';

function AddCategory() {
    const dispatch = useDispatch();
    const {token} = useSelector(state => state.auth);
    const {productLoader} = useSelector((state)=>(state.product));
    const initialData = {title:'',description:''};
    const [formData , setFormData] = useState(initialData);
    const changeHandler = (event) => {
        setFormData((prev) => (
            {
                ...prev,[event.target.name]:event.target.value
            }
        ));
    }
    const submitHandler = (event) => {
        event.preventDefault();
        if(!formData.title.trim()){
            return toast.error('Category Name is Required');
        }
        if(!formData.description.trim()){
            return toast.error('About Category is Required');
        }
        dispatch(createCategory(token,formData,setFormData,initialData));
    }

    return (
        <div className='page-container'>
            <div className='dark-block'>
                <div className='dash-forms'>
                    <form className='dash-form-container' onSubmit={submitHandler} autoComplete='off'>
                        <div className='main-title'>
                            Create Category
                        </div>
                        <div className='main-des'>
                            Add a category to classify your products.
                            <span className='highlight-des'></span>
                        </div>
                        <div className='main-form'>
                            <div className='form-item'>
                                <label htmlFor='title'>Category Name <sup>*</sup></label>
                                <input 
                                    type='text' 
                                    name='title' 
                                    placeholder='Enter category name'
                                    value={formData.title}
                                    onChange={changeHandler}
                                />
                            </div>
                            <div className='form-item'>
                                <label htmlFor='description'>About Category<sup>*</sup></label>
                                <input 
                                    type='text' 
                                    name='description' 
                                    placeholder='Enter about category'
                                    value={formData.description}
                                    onChange={changeHandler}
                                />
                            </div>
                            <button type='submit' className='form-btn' disabled={productLoader ? true : false}>
                                Create Category
                            </button>
                        </div>
                    </form>
                </div>
                {
                productLoader &&
                <div className='loader-overlay'>
                    <div className="dots"></div>
                </div>
                }
            </div>
        </div>
    );
}

export default AddCategory;