import React, { useRef } from 'react';
import '../css/Dashboard.css';
import { Outlet } from 'react-router-dom';
import Sidebar from '../components/core/Dashboard/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import useOnClickOutside from '../hooks/useOnClickOutside';
import {setShowSidebar} from '../slices/sidebarSlice';


function Dashboard() {
    const {userFullDetails,profileLoader} = useSelector((state) => state.profile);
    const {showSidebar} = useSelector((state) => state.sidebar);
    const dispatch = useDispatch();
    const ref=useRef(null);
    useOnClickOutside(ref, () => dispatch(setShowSidebar(false)));
    return (
        <>
            {
                userFullDetails ?
                <div className='dashboard-container'>
                    <div ref={ref} className={`sidebar-container ${showSidebar ? 'show-sidebar' : ''}`}>
                        <Sidebar/>
                    </div>
                    <div className='sub-route-container'>
                        <Outlet/>
                    </div>
                </div>
                : 
                <div className='page-container'>
                    <div className='dark-block'></div>
                    {
                        profileLoader &&
                        <div className='loader-overlay'>
                            <div className="dots"></div>
                        </div>
                    }
                </div>
            }
        </>
            
    );
}

export default Dashboard;