import React from 'react';

function CreateOrder() {
    return (
        <div className='page-container'>
            <div className='dark-block'></div>
        </div>
    );
}

export default CreateOrder;