import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateProfileDetails } from '../../../../services/operations/profileOperations';

function UpdateProfileInfo() {

    

    const {userFullDetails} = useSelector((state) => (state.profile) );
    const {token} = useSelector((state) => (state.auth));
    const [loading , setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {register,handleSubmit,formState: {errors},setValue} = useForm(
        {
            mode:"onTouched"
        }
    );

    const submitData = (data) => {
        setLoading(true)
        dispatch(updateProfileDetails(token,data)).then(
            () => setLoading(false)
        )
    }
    return (
        <>
            <div className='profile-tile'>
                <div className='profile-tile-header'>
                    <div className='tile-name'>
                        Profile Information
                    </div>
                </div>
                <form className='profile-form'>
                    <div className='pf-items-container'>
                        <div className='profile-form-item'>
                            <label htmlFor='firstName'>First Name</label>
                            <input 
                                type='text' 
                                name='firstName' 
                                placeholder='Enter first name'
                                defaultValue={userFullDetails?.firstName}
                                {...register("firstName",{required:true})}
                            />
                            {errors.firstName && <span>Please Enter Your First Name</span>}
                        </div>
                        <div className='profile-form-item'>
                            <label htmlFor='lastName'>Last Name</label>
                            <input 
                                type='text' 
                                name='lastName' 
                                placeholder='Enter last name'
                                defaultValue={userFullDetails?.lastName}
                                {...register("lastName",{required:true})}
                            />
                            {errors.lastName && <span>Please Enter Your Last Name</span>}
                        </div>
                    </div>
                    <div className='pf-items-container'>
                        <div className='profile-form-item'>
                            <label htmlFor='dateOfBirth'>Date Of Birth</label>
                            <input 
                                type='date' 
                                name='dateOfBirth'
                                defaultValue={userFullDetails?.additionalDetails?.dateOfBirth}
                                {...register("dateOfBirth",{
                                    required:{
                                        value:true,
                                        message:"Please Select Your Date of Birth"
                                    },
                                    max: {
                                        value:new Date(new Date().setFullYear(new Date().getFullYear() - 16)).toISOString().split("T")[0],
                                        message : "Minimum Age Should be 16 Years"
                                    }
                                })}
                            />
                            {errors.dateOfBirth && <span>{errors.dateOfBirth.message}</span>}
                        </div>
                        <div className='profile-form-item'>
                            <label htmlFor='gender'>Gender</label>
                            <select 
                            className='custom-select' 
                            name='gender' 
                            defaultValue={userFullDetails?.additionalDetails?.gender}
                            {...register("gender",{required:true})}>
                                <option value='Male'>Male</option>
                                <option value='Female'>Female</option>
                            </select>
                        </div>
                    </div>
                    <div className='pf-items-container'>
                        <div className='profile-form-item'>
                            <label htmlFor='contactNumber'>Contact Number</label>
                            <input 
                                type='text' 
                                name='contactNumber'
                                placeholder='Enter Contact Number'
                                defaultValue={userFullDetails?.additionalDetails?.contactNumber}
                                {...register("contactNumber",{
                                    
                                    required:{
                                        value:true,
                                        message:"Please Enter Your Contact Number"
                                    },
                                    maxLength:{
                                        value:12,
                                        message:"Maximum Length is 12"
                                    },
                                    minLength:{
                                        value:10,
                                        message:"Minimum Length is 10"
                                    },
                                    onChange: (e)=>{
                                        const result = e.target.value.replace(/\D/g, '')
                                        setValue("contactNumber",result)
                                    }
                                })}
                            />
                            {errors.contactNumber && <span>{errors.contactNumber.message}</span>}
                        </div>
                        <div className='profile-form-item'>
                            <label htmlFor='about'>About</label>
                            <input 
                                type='text' 
                                name='about' 
                                placeholder='Enter Bio Details'
                                defaultValue={userFullDetails?.additionalDetails?.about}
                                {...register("about",{required:true})}
                            />
                            {errors.about && <span>Please Enter About Yourself</span>}
                        </div>
                    </div>
                </form>
            </div>
            <div className='cpd-btns'>
                <button type='button' className='cpp-sec-btn' onClick={() => (navigate('/dashboard/my-profile'))}>
                    cancel
                </button>
                <button type='button' onClick={handleSubmit(submitData)} className='cpp-prime-btn'>
                {loading ? <div className="spinner"></div> : 'Save'}
                </button>
            </div>
        </>
    );
}

export default UpdateProfileInfo;