import React from 'react';
import { Link } from 'react-router-dom';

function BtnComponent({linkto,active,children}) {
    return (
        <Link to={linkto} className={ active ? 'primary-btn' : 'secondary-btn'}>
            {children}
        </Link>
    );
}

export default BtnComponent;