import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { editProduct } from '../../../services/operations/productOperations';
import { setProductToEdit } from '../../../slices/productSlice';
import validatebarcode from 'barcode-validator/src/validatebarcode';
import { isMobile } from 'react-device-detect';
import { IoIosCloseCircle, IoMdBarcode } from "react-icons/io";
import { BarcodeScanner } from 'react-barcode-scanner';
import 'react-barcode-scanner/polyfill';

function EditProduct() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {productToEdit,productLoader} = useSelector((state)=>(state.product));
    const {token} = useSelector((state)=>(state.auth));
    const initialData = (productToEdit !== null) ? {id:productToEdit._id,listingStatus:productToEdit.listingStatus,title:productToEdit.title,description:productToEdit.description,costPrice:productToEdit.costPrice,sellingPrice:productToEdit.sellingPrice,mrp:productToEdit.mrp,flag:productToEdit.flag,itemCode:productToEdit.itemCode,quantity:productToEdit.quantity} : '';
    const [barScanner,setBarScanner] = useState(false);
    const [formData,setFormData] = useState(initialData);
    const changeHandler = (event) => {
        if (event.target.name === 'costPrice' || event.target.name === 'sellingPrice' || event.target.name === 'mrp' || event.target.name === 'quantity' )
            {
                const result = event.target.value.replace(/[^0-9.]/g, '')
                event.target.value=result
    
            }
            setFormData((prev) => (
                {
                    ...prev,[event.target.name]:event.target.value
                }
            ));
    }
    const submitHandler = (event) => {
        event.preventDefault();
        var regexp = /^\d+(\.\d{1,2})?$/;
        var regexp2 = /^\d+(\.\d{1,3})?$/;
        if(!formData.title.trim()){
            return toast.error('Product Name is Required');
        }
        if(!formData.description.trim()){
            return toast.error('About Product is Required');
        }
        if(!formData.itemCode){
            return toast.error('Product Code is Required');
        }
        if(formData.itemCode !== 'NOCODE' && !validatebarcode(formData.itemCode)){
            return toast.error('Enter a Valid Product Code or NOCODE');
        }
        if(!formData.quantity){
            return toast.error('Quantity is Required');
        }
        if(!regexp2.test(formData.quantity)){
            return toast.error('Enter Valid Quantity upto 3 decimals');
        }
        if(!formData.costPrice){
            return toast.error('Cost Price is Required');
        }
        if(!regexp.test(formData.costPrice)){
            return toast.error('Enter Valid Cost Price upto 2 decimals');
        }
        if(!formData.sellingPrice){
            return toast.error('Selling Price is Required');
        }
        if(!regexp.test(formData.sellingPrice)){
            return toast.error('Enter Valid Selling Price upto 2 decimals');
        }
        if(!formData.mrp){
            return toast.error('MRP is Required');
        }
        if(!regexp.test(formData.mrp)){
            return toast.error('Enter Valid MRP upto 2 decimals');
        }
        dispatch(editProduct(token,formData,navigate))
    }
    if(productToEdit !== null){
        return(
            <div className='page-container'>
                <div className='dark-block'>
                    <div className='dash-forms'>
                        <form className='dash-form-container' onSubmit={submitHandler} autoComplete='off'>
                            <div className='main-title'>
                                Edit Product
                            </div>
                            <div className='main-des'>
                                Edit product information to change your product's details.
                                <span className='highlight-des'>If you want to change Product Category or Product Image Then You should delete Product and create a fresh entry.</span>
                            </div>
                            <div className='radio-btn'>
                                <label>
                                    <input
                                    type='radio'
                                    name='listingStatus'
                                    value='active'
                                    onChange={changeHandler}
                                    checked = {(formData.listingStatus === 'active') ? true : false}/>
                                    <span>Active</span>
                                </label>
                                <label>
                                    <input
                                    type='radio'
                                    name='listingStatus'
                                    value='inactive'
                                    onChange={changeHandler}
                                    checked = {(formData.listingStatus === 'inactive') ? true : false}
                                    />
                                    <span>Inactive</span>
                                </label>
                            </div>
                            <div className='main-form'>
                                <div className='form-item'>
                                    <label htmlFor='title'>Product Name <sup>*</sup></label>
                                    <input 
                                        type='text' 
                                        name='title' 
                                        placeholder='Enter product name'
                                        value={formData.title}
                                        onChange={changeHandler}
                                    />
                                </div>
                                <div className='form-item'>
                                    <label htmlFor='description'>About Product<sup>*</sup></label>
                                    <input 
                                        type='text' 
                                        name='description' 
                                        placeholder='Enter about product'
                                        value={formData.description}
                                        onChange={changeHandler}
                                    />
                                </div>
                                <div className='form-items-container'>
                                <div className='form-item'>
                                    <label htmlFor='itemCode'>Product Code (UPC)<sup>*</sup></label>
                                    <input 
                                        type='text' 
                                        name='itemCode' 
                                        placeholder='Enter Product Code'
                                        value={formData.itemCode}
                                        onChange={changeHandler}
                                    />
                                    {
                                        isMobile && 
                                        <div className='barcode-scanner-btn' onClick={()=>(setBarScanner(true))}>
                                            <IoMdBarcode/>
                                        </div>
                                    }    
                                </div>
                                <div className='form-item'>
                                    <label htmlFor='quantity'>Quantity<sup>*</sup></label>
                                    <input 
                                        type='text' 
                                        name='quantity' 
                                        placeholder='Enter Quantity'
                                        value={formData.quantity}
                                        onChange={changeHandler}
                                    />
                                </div>
                            </div>
                                <div className='form-items-container'>
                                    <div className='form-item'>
                                        <label htmlFor='costPrice'>Cost Price<sup>*</sup></label>
                                        <input 
                                            type='text' 
                                            name='costPrice' 
                                            placeholder='Enter cost price'
                                            value={formData.costPrice}
                                            onChange={changeHandler}
                                        />
                                    </div>
                                    <div className='form-item'>
                                        <label htmlFor='sellingPrice'>Selling Price<sup>*</sup></label>
                                        <input 
                                            type='text' 
                                            name='sellingPrice' 
                                            placeholder='Enter selling price'
                                            value={formData.sellingPrice}
                                            onChange={changeHandler}
                                        />
                                    </div>
                                </div>
                                <div className='form-items-container'>
                                    <div className='form-item'>
                                        <label htmlFor='mrp'>MRP<sup>*</sup></label>
                                        <input 
                                            type='text' 
                                            name='mrp' 
                                            placeholder='Enter MRP'
                                            value={formData.mrp}
                                            onChange={changeHandler}
                                        />
                                    </div>
                                    <div className='form-item'>
                                        <label htmlFor='flag'>Flag<sup>*</sup></label>
                                        <select  
                                        name='flag'
                                        value={formData.flag}
                                        onChange={changeHandler}
                                        >
                                            <option value='none'>None</option>
                                            <option value='new'>New</option>
                                            <option value='trending'>Trending</option>
                                            <option value='popular'>Popular</option>
                                        </select>
                                    </div>
                                </div>
                                <button type='submit' className='form-btn' disabled={productLoader ? true : false}>
                                    Edit Product
                                </button>
                                <button type='button' className='sec-form-btn' onClick={()=>{navigate('/dashboard/my-products');dispatch(setProductToEdit(null))}}>
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>
                    {
                    productLoader &&
                    <div className='loader-overlay'>
                        <div className="dots"></div>
                    </div>
                    }
                    {
                        barScanner &&
                        <div className='barcode-scanner-container'>
                            <IoIosCloseCircle onClick={()=>setBarScanner(false)} className='barcode-scanner-close-btn'/>
                            <div className='barcode-scanner'>
                                <BarcodeScanner onCapture={(barcode)=>{setBarScanner(false); setFormData((prev) => ({   ...prev,itemCode:barcode.rawValue}));}} options={{formats:['ean_13','ean_8','upc_a','upc_e'],delay:500}}/>
                            </div>
                            <div>Scan Bar Code</div>
                        </div>
                    }
                </div>
            </div>
        )
    }
    else
    {   
        return <Navigate to='/dashboard/my-products'/>
    }
}

export default EditProduct;