import React from 'react';

function HighlightText({text}) {
    return (
        <span className='gradient-txt'>
            {text}
        </span>
    );
}

export default HighlightText;