import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { ACCOUNT_TYPE } from '../../../utils/constants';

function OpenRoute({children}) {
    const {token} = useSelector((state) => (state.auth));
    const {user} = useSelector((state) => (state.profile));
    if(token === null){
        return children
    }
    else
    {
        if(user.accountType !== ACCOUNT_TYPE.CONSUMER){
            return <Navigate to='/dashboard/my-products'/>
        }
        else
        {
            return <Navigate to='/'/>
        }
    }
}

export default OpenRoute;