import React from 'react';
import {FaArrowRight} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import HighlightText from '../components/core/home/HighlightText';
import BtnComponent from '../components/core/home/BtnComponent';
import Footer from '../components/core/common/Footer';
import Products from '../components/core/home/Products';


function Home() {
    return (
        <div className='page-wrapper'>
            <div className='dark-block'>
                <div className='hp-section-1'>
                    <Link to='/signup' className='round-btn'>
                        <div className='round-btn-content'>
                            <span>Become a Member</span>
                            <FaArrowRight fontSize={'16px'}/>
                        </div>
                    </Link>
                    <div className='title-des'>
                        <div className='main-title'>Your one-stop shop for <HighlightText text={" mobile accessories, stationery, groceries, "} /> and more.</div>
                        <div className='main-des'>
                            Discover a world of convenience and quality with RK eServices. Whether you're looking for the latest mobile accessories, stylish stationery, everyday groceries, or essential services, we offer everything you need under one roof. Explore our diverse range of products and services, designed to meet your needs and exceed your expectations. 
                        </div>
                    </div>
                    <div className='btns-stack'>
                        <BtnComponent linkto={'/catalog'} active={true}>Shop Now</BtnComponent>
                        <BtnComponent linkto={'/about'} active={false}>Learn More</BtnComponent>
                    </div>
                </div>
                <div className='home-products-title'>New in <span className='gradient-txt'> Mobile Accessories&nbsp;</span></div>
                <Products category={"Mobile Accessories"} flag= {"new"} number={4}/>
                <div className='home-products-title'>Popular in <span className='gradient-txt'> Mobile Accessories&nbsp;</span></div>
                <Products category={"Mobile Accessories"} flag= {"popular"} number={8}/>
                <div className='home-products-title'>Trending in <span className='gradient-txt'> Mobile Accessories&nbsp;</span></div>
                <Products category={"Mobile Accessories"} flag= {"trending"} number={4}/>
                <div className='home-products-title'>New in <span className='gradient-txt'> Stationery&nbsp;</span></div>
                <Products category={"Stationery"} flag= {"new"} number={4}/>
                <div className='home-products-title'>Popular in <span className='gradient-txt'> Stationery&nbsp;</span></div>
                <Products category={"Stationery"} flag= {"popular"} number={8}/>
            </div>
            <Footer/>
        </div>
    );
}

export default Home;