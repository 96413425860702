import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    productLoader:false,
    myLoader:false,
    myListLoader:false,
    categoryLoader:true,
    productCategories:[],
    allProducts:[],
    productToEdit:null,
    myList:[],
}

export const productSlice = createSlice({
    name:'product',
    initialState:initialState,
    reducers:{
        setProductLoader:(state,value) => {
            state.productLoader = value.payload
        },
        setMyLoader:(state,value) => {
            state.myLoader = value.payload
        },
        setMyListLoader:(state,value) => {
            state.myListLoader = value.payload
        },
        setCategoryLoader:(state,value) => {
            state.categoryLoader = value.payload
        },
        setProductCategories:(state,value) => {
            state.productCategories = value.payload
        },
        setAllProducts:(state,value) => {
            state.allProducts = value.payload
        },
        setProductToEdit:(state,value) => {
            state.productToEdit = value.payload
        },
        setMyList:(state,value) => {
            state.myList = value.payload
        }
    }
});

export const {setProductLoader,setMyLoader,setMyListLoader,setProductCategories,setAllProducts,setProductToEdit,setMyList,setCategoryLoader} = productSlice.actions;
export default productSlice.reducer;

