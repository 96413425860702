import React, { useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { changeProfilePicture } from '../../../../services/operations/profileOperations';
import { MdOutlineCloudUpload } from 'react-icons/md'

function ChangeProfileImg() {
    const {userFullDetails} = useSelector((state) => state.profile);
    const [profileImg,setProfileImg] = useState(userFullDetails.profilePicture);
    const [imgFile,setImgFile] = useState(null);
    const [loading,setLoading] = useState(false);
    const ref = useRef(null);
    const dispatch = useDispatch();
    const {token} = useSelector((state) => state.auth);
    const handleImgSelect = (e) => {
        const file = e.target.files[0];
        if(file){
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setProfileImg(reader.result);
                setImgFile(file);
            }
        }
        else{
            setProfileImg(userFullDetails.profilePicture);
            setImgFile(null)
        }
    }
    const handleFileUpload = (e) => {
        e.preventDefault();
        setLoading(true);
        try{
            if(!imgFile){
                setLoading(false);
                return toast.error('Please select file');
            }
            const formData = new FormData();
            formData.append("picture" , imgFile);
            dispatch(changeProfilePicture(token,formData)).then(
                () => {setLoading(false);}
            )
        }
        catch(error){
            console.log(error);
        }
    }
    return (
        <div className='profile-tile'>
            <div className='profile-tile-header'>
                <div className='profile-picture-edit'>
                    <img src={profileImg} alt='profile_picture' />
                    <form className='change-profile-picture' onSubmit={handleFileUpload}>
                        <div className='cpp-txt'>Change Profile Picture</div>
                        <input type='file' accept='image/jpg, image/gif, image/png, image/jpeg' onChange={handleImgSelect} ref={ref} hidden/>
                        <div className='cpp-btns'>
                            <button type='button' className='cpp-sec-btn' onClick={() => (ref.current.click())}>
                                Select
                            </button>
                            <button type='submit' className='cpp-prime-btn'>
                                {loading ? <div className="spinner"></div> : <span className='cpp-inside'>Upload <MdOutlineCloudUpload/></span>}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ChangeProfileImg;