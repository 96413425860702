import React from 'react';
import Footer from '../components/core/common/Footer';
import '../css/Static.css';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';

function About() {
    return (
        <>
            <div className='page-container'>
                <div className="static-title-container">
                    <div className="static-title">
                        <div className='gradient-txt'>About Us</div>
                        <div className='small-border'></div>
                    </div>
                </div>
                <div className='dark-block'>
                    <div className='main-about'>
                        <FaQuoteLeft/>&nbsp;
                            Welcome to <span className="gradient-txt"> RK eServices </span>, the online gateway to our two distinct and specialized retail experiences: 
                            <span class="gradient-txt2"> R.K Mobile </span> and <span class="gradient-txt3"> R.K Provisional </span>. We are committed to providing exceptional products and services through our 
                            two shops, each dedicated to fulfilling different aspects of your daily needs.&nbsp; 
                        <FaQuoteRight/>
                    </div>
                    <div className='seprater'></div>
                    <div className='about-sections-container'>
                        <div className='about-section'>
                            <div className='about-section-title'>
                                R.K Mobile 
                            </div>
                            <div className='about-section-des'>
                                At R.K Mobile, we go beyond just offering the latest in mobile accessories and stationery. In addition to a wide selection of phone cases, chargers, and stylish stationery, we are proud to provide essential banking and Common Service Centre (CSC) services. Whether you need assistance with banking transactions, bill payments, or accessing government services through CSC, our knowledgeable team is here to assist you with efficiency and ease. RK Mobile is your comprehensive solution for tech accessories, stationery, and important financial and service needs.
                            </div>
                        </div>
                        <div className='about-section'>
                            <div className='about-section-title'>
                                R.K Provisional 
                            </div>
                            <div className='about-section-des'>
                                R.K Provisional is your trusted neighborhood grocery store, dedicated to offering a broad selection of high-quality food products and everyday essentials. From fresh produce and pantry staples to various groceries, we ensure that our shelves are stocked with items that meet your needs and exceed your expectations. Our focus on quality and affordability makes RK Provisional the ideal place for your grocery shopping, blending convenience with value.
                            </div>
                        </div>
                        <div className='about-section'>
                            <div className='about-section-title'>
                                Our Commitment 
                            </div>
                            <div className='about-section-des'>
                                At RK eServices, we are more than just retailers; we are dedicated to enhancing your shopping experience with personalized service and comprehensive support. Our goal is to build lasting relationships with our customers by providing a diverse range of products and services, ensuring that each visit is met with satisfaction.
                            </div>
                        </div>
                        <div className='about-section'>
                            <div className='about-section-title'>
                                Why Choose Us? 
                            </div>
                            <div className='about-section-des'>
                                <p><b>Comprehensive Services: </b> From mobile accessories and stationery to banking and CSC services at RK Mobile, and a wide range of groceries at RK Provisional, we offer everything you need under one roof.</p>
                                <p><b>Quality and Affordability: </b>We are committed to delivering high-quality products and services at competitive prices.</p>
                                <p><b>Customer-Focused Approach: </b>Our team is dedicated to providing exceptional customer service, ensuring that your experience with us is smooth and enjoyable.</p>
                                <p>Thank you for choosing RK eServices. We look forward to serving you at R.K Mobile and R.K Provisional, where our commitment to quality and customer satisfaction drives everything we do. Should you have any questions or require assistance, please don't hesitate to reach out to us.</p>
                            </div>
                        </div>
                        <div className='about-section'>
                            <div className='about-section-title'>
                                Connect with Us 
                            </div>
                            <div className='about-section-des'>
                                For more information, visit our contact page or drop by our stores. We are here to help and provide you with the best possible service.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}

export default About;