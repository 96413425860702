import React, { useState } from 'react';
import { sidebarLinks } from '../../../data/dashboard-links';
import { Link, matchPath, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Icons from  'react-icons/vsc';
import ModalAlert from '../common/ModalAlert';
import {logout} from '../../../services/operations/authOperations';
import {setShowSidebar} from '../../../slices/sidebarSlice';
import { ACCOUNT_TYPE } from '../../../utils/constants';

function Sidebar() {
    const {user} = useSelector((state) => (state.profile));
    const SettingsIcon = Icons['VscSettingsGear'];
    const LogoutIcon = Icons['VscSignOut'];
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [modal , setModal] = useState(false);
    const matchRoute = (route) => {
        return matchPath({path:route},location.pathname);
    }
    return (
        <>
            {sidebarLinks.map((link) => {
                const Icon = Icons[link.icon];
                if(link?.type && link?.type !== user.accountType && user.accountType !== ACCOUNT_TYPE.ADMIN)
                {return null}
                if(link?.type && link?.type === ACCOUNT_TYPE.CONSUMER && user.accountType === ACCOUNT_TYPE.ADMIN)
                {return null}
                else
                {
                    return (
                            <Link 
                            to={link.path} 
                            key={link.id} 
                            className={`sidebar-link ${matchRoute(link.path) ? 'active-sidebar' : ''}`}
                            onClick={() => dispatch(setShowSidebar(false))}
                            >
                                <Icon fontSize='20px'/>{link.name}
                            </Link>
                        )
                }

            }
            )}
            <div className='sidebar-seprator'></div>
            <Link 
            to='/dashboard/settings' 
            className={`sidebar-link ${matchRoute('/dashboard/settings') ? 'active-sidebar' : ''}`}
            onClick={() => dispatch(setShowSidebar(false))}
            >
                <SettingsIcon fontSize='20px'/> Settings
            </Link>
            <div className='sidebar-link' onClick={() => setModal(true)}>
                <LogoutIcon fontSize='20px'/> Logout
            </div>
            {
                modal && 
                <ModalAlert 
                    txt1 = 'Are you sure ?'
                    txt2 = 'You will be logged out of your account.'
                    btn1txt = 'Logout'
                    btn2txt = 'Cancel'
                    handler1 = {() => (dispatch(logout(navigate)))}
                    handler2 = {() => (setModal(false))}
                />
            }
        </>
    );
}

export default Sidebar;