import { Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BsImageFill } from 'react-icons/bs';
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import { useDispatch, useSelector } from 'react-redux';
import { FilePathCon } from '../../../constant';
import { addToWishlist, deleteWishlistProduct, fetchHomeProducts } from '../../../services/operations/productOperations';
import { ACCOUNT_TYPE } from '../../../utils/constants';

function Products(props) {
    const createArray =(N) => {
        let newArr = [];
        for (let i = 1; i <= N; i++) {
            newArr.push(i);
        }
        return newArr;
    }
    const dispatch = useDispatch();
    const [catalogList,setCatalogList] = useState([]);
    const [catalogLoader,setCatalogLoader] =useState(true);
    const {productLoader,productCategories,myList} = useSelector((state)=>(state.product));
    const {token} = useSelector((state) => (state.auth));
    const {user} = useSelector((state) => (state.profile));
    const [imgLoaded,setImgLoaded] = useState([]);
    const [modal,setModal] = useState(false);
    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const catName = props.category;
    const flag = props.flag;
    const number = props.number;
    const skeletonCount = createArray(number);
    const findCategory = productCategories.find((category) => (category.title === catName));
    useEffect(() => {
        setCatalogList([]);
        if (findCategory){
            dispatch(fetchHomeProducts(findCategory._id,flag,number,setCatalogList,setCatalogLoader));
        }
      },[productCategories]);
    return (
        <div className='page-container' style={{position:"relative"}}>
            <div className='dark-block'>
                { 
                catalogLoader ?
                <div className='products-container' style={{maxWidth:'1200px',margin:'auto'}}>
                    {   
                        skeletonCount.map(
                            (count) => (
                                <div className='product-tile' key={count}>
                                    <div className="product-image-container">
                                    <Skeleton
                                        sx={{ bgcolor: 'rgba(22,29,41,0.9)' }}
                                        variant="rounded"
                                        animation="wave"
                                        width={260}
                                        height={220}
                                    />
                                    <BsImageFill style={{color:'#838894',position:'absolute',fontSize:'1.5rem'}}/>
                                    
                                    </div>
                                    <div className='product-title'><Skeleton variant="rounded" height={20} sx={{ fontSize: '1rem',bgcolor: 'rgba(0,8,20,0.9)'}}/></div>
                                    <div className='price-details'>
                                        <div className='selling-price'>
                                        <Skeleton variant="rounded" height={20} width={35} sx={{ fontSize: '1rem',bgcolor: 'rgba(0,8,20,0.9)'}}/>
                                        <Skeleton variant="rounded" height={15} width={80} sx={{ fontSize: '1rem',bgcolor: 'rgba(0,8,20,0.9)'}}/>
                                        </div>
                                    </div>
                                </div>
                            )
                        )
                    }
                </div> :
                <div className='products-container' style={{maxWidth:'1200px',margin:'auto'}}>
                    {   
                        catalogList.length > 0 ?
                        catalogList.map(
                            (product) => (
                                <div className='product-tile' key={product._id}>
                                    <div className="product-image-container">
                                        {
                                            !imgLoaded.includes(product._id) && 
                                            <>
                                                <Skeleton
                                                sx={{ bgcolor: 'rgba(22,29,41,0.9)' }}
                                                variant="rounded"
                                                animation="wave"
                                                width={260}
                                                height={220}
                                                />
                                                <BsImageFill style={{color:'#838894',position:'absolute',fontSize:'1.5rem'}}/>
                                            </>
                                        }
                                        <img src={FilePathCon + product.thumbnail} style={!imgLoaded.includes(product._id) ? {display:'none'} : {display:'block'}} alt='img' className='product-image' onLoad={()=>(setImgLoaded((prev)=>[...prev,product._id]))} />
                                    </div>
                                    <div className='product-title'>{product.description}</div>
                                    <div className='price-details'>
                                        <div className='selling-price'>
                                            &#8377; {product.sellingPrice}
                                            {
                                                parseInt(product.sellingPrice) < parseInt(product.mrp) ?
                                                <div className='mrp-container'>
                                                    <span>MRP: <del>&#8377; {product.mrp}</del></span>
                                                    &nbsp;
                                                    <span className='off-on-mrp'>
                                                        {Math.round((parseInt(product.mrp)-parseInt(product.sellingPrice))*100/parseInt(product.mrp))}
                                                        &#37; off
                                                    </span>
                                                </div> 
                                                : 
                                                ''
                                            }
                                        </div>
                                        {
                                            product.flag !== 'none' &&
                                            <div className='product-flag'>{capitalizeFirstLetter(product.flag)}</div>
                                        }
                                        {
                                            token !== null && user!==null && user.accountType === ACCOUNT_TYPE.CONSUMER &&
                                            <div className='edit-delete-product'>
                                                {
                                                    !myList.some((prod) => (prod._id === product._id)) ?
                                                    <button className='edit-product-btn' style={{fontSize:'1.1rem'}} onClick={() => {dispatch(addToWishlist(token,product._id))}}><IoMdHeartEmpty/></button>
                                                    : 
                                                    <button className='edit-product-btn' style={{fontSize:'1.1rem'}} onClick={() => {dispatch(deleteWishlistProduct(token,product._id,setModal))}}><IoMdHeart/></button>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        ) : 
                        <div className='not-found'>No Product Found</div>
                    }

                </div>
                }
            </div>
            {
                productLoader &&
                <div className='loader-overlay'>
                    <div className="dots"></div>
                </div>
            }
        </div>
    );
}

export default Products;